import { useState } from "react";
import cx from "classnames";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import * as s from "./styles";

export interface Props {
  backgroundColor?: string;
  initialValue?: number;
  minLabel?: string;
  maxLabel?: string;
  onChange?: (value: number) => void;
  title?: string;
}

export default function Rating({
  backgroundColor = "bg-white",
  initialValue,
  minLabel = "",
  maxLabel = "",
  onChange,
  title,
}: Props): JSX.Element {
  const [rating, setRating] = useState<number | null>(null);

  const handleClickRating = (rating: number): void => {
    setRating(rating);

    onChange?.(rating);
  };

  return (
    <div className={cx(s.wrapper, backgroundColor)}>
      {title && (
        <Text gutter="md" weight="bold">
          {title}
        </Text>
      )}
      <div className="flex flex-col">
        <div className={s.ratingWrapper}>
          {/* Since the scale starts at 0, we need to add 1 to the maximum rating value to generate the correct number of points */}
          {Array.from({ length: 11 }).map((_, index) => {
            const isSelected = index === (rating ?? initialValue);

            return (
              <div
                className={cx("relative w-[48px] min-w-[48px] max-w-[48px]", {
                  "-top-[1px]": isSelected,
                })}
                key={`rating-${index}`}
              >
                <Button
                  onClick={() => handleClickRating(index)}
                  variant={isSelected ? "secondary" : "quaternary"}
                  width="full"
                >
                  {index}
                </Button>
              </div>
            );
          })}
        </div>
        {minLabel && maxLabel && (
          <div className={s.labelsWrapper}>
            <Text color="secondary" size="xs">
              {minLabel}
            </Text>
            <Text color="secondary" size="xs">
              {maxLabel}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
