import { useState } from "react";
import { graphql, useMutation } from "react-relay";
import { useForm } from "react-hook-form";
import {
  ArrowIcon,
  Button,
  Input,
  Text,
  toast,
  ToastContainer,
} from "@olivahealth/oli-ui";
import { VALID_EMAIL_REGEXP } from "@olivahealth/constants";
import useTranslation from "../../../../hooks/useTranslation";
import { ForgotPasswordFormMutation as IMutation } from "./__generated__/ForgotPasswordFormMutation.graphql";

interface ForgotPasswordFormFields {
  email: string;
}

const ForgotPasswordFormMutation = graphql`
  mutation ForgotPasswordFormMutation($email: ID!) {
    resetPassword(email: $email) {
      success
    }
  }
`;

export default function ForgotPasswordForm({
  toggleAuthMethod,
}: {
  toggleAuthMethod: () => void;
}): JSX.Element {
  const [resetPassword] = useMutation<IMutation>(ForgotPasswordFormMutation);
  const { register, handleSubmit, reset, formState } =
    useForm<ForgotPasswordFormFields>({
      mode: "onChange",
    });
  const { t } = useTranslation("auth", { keyPrefix: "forgotPasswordForm" });
  const [formError, setFormError] = useState<string>("");

  const isButtonDisabled =
    !formState.isDirty || !formState.isValid || formState.isSubmitting;

  const onSubmit = handleSubmit(({ email }) => {
    resetPassword({
      variables: {
        email,
      },
      onCompleted: () => {
        toast({ body: t("successMessage"), variant: "success" });
        reset();
      },
      onError: (error) => {
        setFormError(error.message ?? t("errorMessage"));
      },
    });
  });

  return (
    <>
      <ToastContainer limit={1} />
      <form
        className="animate animate-fade-in duration-1000 w-full"
        onSubmit={onSubmit}
      >
        <span className="flex mb-6 w-full items-center justify-center relative">
          <span className="absolute left-0">
            <Button
              variant="icon"
              aria-label={t("goBackLinkAriaLabel")}
              type="button"
              size="xs"
              onClick={toggleAuthMethod}
            >
              <ArrowIcon direction="left" size={24} />
            </Button>
          </span>
          <Text variant="h3" align="center">
            {t("title")}
          </Text>
        </span>
        <Text gutter="lg">{t("description")}</Text>
        <div className="flex flex-col gap-6">
          <Input
            id="email"
            register={register}
            label={t("email.label")}
            placeholder={t("email.placeholder")}
            type="email"
            validationOptions={{
              pattern: VALID_EMAIL_REGEXP,
            }}
            hasError={Boolean(formState.errors.email)}
            errorMessage={t("email.error")}
          />
          <Button
            variant="primary"
            type="submit"
            width="full"
            disabled={isButtonDisabled}
          >
            {t("buttonLabel")}
          </Button>
          {formError !== "" && <Text color="error">{formError}</Text>}
        </div>
      </form>
    </>
  );
}
