import { IconProps, SvgIcon } from "./SvgIcon";

export function ResourcesIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <circle cx="6.5" cy="7" r="3.5" fill={color} />
      <circle cx="6.5" cy="17" r="3.5" fill={color} />
      <circle cx="17.5" cy="17" r="3.5" fill={color} />
      <path
        d="M17.5 4L17.5 10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M20.5 7L14.5 7"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
