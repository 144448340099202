import { IconProps, SvgIcon } from "./SvgIcon";

export function CheckIcon({
  className,
  color = "currentColor",
  size,
  strokeWidth = "2",
}: Readonly<IconProps>): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M5 12.8301L8.15644 17.1248C9.00582 18.2804 10.6717 18.2938 11.5381 17.152L20 6"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
