import { IconProps, SvgIcon } from "./SvgIcon";

export function PencilIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M6.75975 13.6833C6.72986 12.8508 7.04746 12.0434 7.6365 11.4544L15.4849 3.60593C16.6954 2.39548 18.6579 2.39548 19.8683 3.60593C21.0788 4.81637 21.0788 6.7789 19.8683 7.98935L12.0199 15.8378C11.4309 16.4268 10.6235 16.7444 9.79097 16.7145L7.79488 16.6429C7.27076 16.6241 6.85022 16.2035 6.8314 15.6794L6.75975 13.6833Z"
        stroke={color}
        strokeWidth="2"
      />
      <path d="M3 21H21" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </SvgIcon>
  );
}
