import { IconProps, SvgIcon } from "./SvgIcon";

export function InterconnectedUsersIcon({
  className,
  color = "currentColor",
  fill,
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon fill={fill} size={size} className={className}>
      <circle cx="7.53247" cy="4" r="2" fill={color} />
      <circle cx="16.4677" cy="4" r="2" fill={color} />
      <path
        d="M8.66667 8.41729C8.14537 8.22567 7.58439 8.1214 7 8.1214C4.23858 8.1214 2 10.4495 2 13.3214L2 15.9214C2 18.7933 4.23858 21.1214 7 21.1214C9.76142 21.1214 12 18.7933 12 15.9214V13.3214C12 10.4495 14.2386 8.1214 17 8.1214C19.7614 8.1214 22 10.4495 22 13.3214V15.9214C22 18.7933 19.7614 21.1214 17 21.1214C15.9115 21.1214 14.9043 20.7597 14.0833 20.1455"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
