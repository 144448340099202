import { IconProps, SvgIcon } from "./SvgIcon";

interface Props extends IconProps {
  variant?: "default" | "open";
}

export function ShareIcon({
  className,
  color = "currentColor",
  size,
  variant,
}: Props): JSX.Element {
  if (variant === "open") {
    return (
      <SvgIcon size={size} className={className}>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeWidth={2}
          d="M10.5 5H8.455A5.455 5.455 0 0 0 3 10.454v4.091A5.455 5.455 0 0 0 8.455 20h4.09A5.455 5.455 0 0 0 18 14.546v-2.421"
        />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeWidth={2}
          d="M17.393 5.959a9.434 9.434 0 0 0-6.32 3.358 9.42 9.42 0 0 0-2.152 5.176"
        />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeWidth={2}
          d="m17.023 9.07 1.324-2.379a1.377 1.377 0 0 0-.462-1.83l-2.294-1.466"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M9 13.5L15 16.5M15 7.5L9 10.5M18 21C16.3431 21 15 19.6569 15 18C15 16.3431 16.3431 15 18 15C19.6569 15 21 16.3431 21 18C21 19.6569 19.6569 21 18 21ZM6 15C4.34315 15 3 13.6569 3 12C3 10.3431 4.34315 9 6 9C7.65685 9 9 10.3431 9 12C9 13.6569 7.65685 15 6 15ZM18 9C16.3431 9 15 7.65685 15 6C15 4.34315 16.3431 3 18 3C19.6569 3 21 4.34315 21 6C21 7.65685 19.6569 9 18 9Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
