import {
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { useAuth } from "@olivahealth/firebase/client";
import { useIntercom } from "react-use-intercom";
import { useRouter } from "next/router";
import { graphql, useMutation } from "react-relay";
import { AuthErrorCodes, createUserWithEmailAndPassword } from "@firebase/auth";
import { refreshAuthToken } from "@olivahealth/utils/relayUtils";
import { CURRENT_ONBOARDING_VERSION } from "@olivahealth/constants";
import logger from "@olivahealth/logger/client";
import { NexusGenObjects } from "@olivahealth/graphql-server/src/ui/types/graphql.gen";
import React, { FormEventHandler, useState } from "react";
import { CustomerEngagementServiceEvents } from "@olivahealth/graphql-server/src/domain/value-objects/CustomerEngagementServiceEvents";
import { useAmplitude } from "../../../../services/contexts/AmplitudeContext";
import { useAuthAuthorized } from "../../../../services/contexts/AuthAuthorizedContext";
import { OlivaHook } from "../../../../hooks/OlivaHook";
import useTranslation from "../../../../hooks/useTranslation";
import { useSignOut } from "../../../../services/contexts/SignOutContext";
import { AuthMethod } from "../AuthPanel/types";
import useSSOVerifyEmail from "../../../../hooks/useSSOVerifyEmail";

import { UserRoleEnum } from "../../../pages/Impersonate/__generated__/ImpersonateQuery.graphql";
import { useSignUpWithEmailFormAcceptInvitationMutation as Mutation } from "./__generated__/useSignUpWithEmailFormAcceptInvitationMutation.graphql";
import useFixInvitationStaleUser from "./useFixInvitationStaleUser";

interface SignUpFormFields {
  name: string;
  surname: string;
  email: string;
  password: string;
  preferredLanguage: string;
  role: string;
}

interface HookProps {
  invitation?: Pick<NexusGenObjects["Invitation"], "id" | "email">;
  toggleAuthMethod: (authMethod: AuthMethod) => () => void;
  setEmail: (email: string) => void;
}

interface UseSignUpWithEmailFormData {
  isButtonDisabled: boolean;
  formError: string;
  formInputProps: {
    register: UseFormRegister<SignUpFormFields>;
    errors: FieldErrors<SignUpFormFields>;
  };
  isPasswordVisible: boolean;
  passwordValidation: {
    length: boolean;
    uppercase: boolean;
    symbol: boolean;
    number: boolean;
  };
}

interface UseSignUpWithEmailFormEffects {
  handleChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFormSubmit: FormEventHandler<HTMLFormElement>;
  handleSubmit: UseFormHandleSubmit<SignUpFormFields>;
  togglePasswordVisibility: () => void;
}

type SignUpWithEmailForm = OlivaHook<
  UseSignUpWithEmailFormData,
  UseSignUpWithEmailFormEffects
>;

const useSignUpWithEmailFormAcceptInvitationMutation = graphql`
  mutation useSignUpWithEmailFormAcceptInvitationMutation($invitationId: ID!) {
    createUser: acceptInvitation(invitationId: $invitationId) {
      __typename
      ... on UserCreated {
        uid
      }
      ... on ForbiddenError {
        reason
      }
      ... on NotFoundError {
        reason
      }
    }
  }
`;

export default function useSignUpWithEmailForm({
  invitation,
  toggleAuthMethod,
  setEmail,
}: HookProps): SignUpWithEmailForm {
  const verifyEmail = useSSOVerifyEmail();
  const [formError, setFormError] = useState<string>("");
  const auth = useAuth();
  const { signOut } = useSignOut();
  const { trackEvent, identifyAmplitudeUser } = useAmplitude();
  const { trackEvent: trackIntercomEvent } = useIntercom();
  const { push } = useRouter();
  const { t } = useTranslation("auth");
  const { setStatus, setErrorMessage } = useAuthAuthorized();
  const {
    effects: { fixInvitationStaleUser },
  } = useFixInvitationStaleUser({
    invitationId: invitation?.id,
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { register, handleSubmit, formState } = useForm<SignUpFormFields>({
    mode: "onChange",
  });
  const [mutateAcceptInvitation, loadingAcceptInvitation] =
    useMutation<Mutation>(useSignUpWithEmailFormAcceptInvitationMutation);

  /**
   * Password validation rules
   */
  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordUppercase, setPasswordUppercase] = useState(false);
  const [passwordSymbol, setPasswordSymbol] = useState(false);
  const [passwordNumber, setPasswordNumber] = useState(false);

  const isButtonDisabled =
    formState.isSubmitting ||
    !passwordLength ||
    !passwordUppercase ||
    !passwordSymbol ||
    !passwordNumber;

  const FORM_GENERIC_ERROR_MESSAGE = t("genericSignUpErrorMessage");

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((isVisible) => !isVisible);
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const uppercaseRegex = new RegExp(/\p{Lu}/u);
    const symbolRegex = new RegExp(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
    const numberRegex = new RegExp(/\d/);

    setPasswordLength(value.length >= 8);
    setPasswordUppercase(uppercaseRegex.test(value));
    setPasswordSymbol(symbolRegex.test(value));
    setPasswordNumber(numberRegex.test(value));
  };

  const triggerMutation = (config) => {
    if (invitation) {
      mutateAcceptInvitation({
        variables: {
          invitationId: invitation.id,
        },
        ...config,
      });
    }
  };

  const handleError = (errorMessage: string) => {
    setFormError(errorMessage);
    setErrorMessage(errorMessage);
    setStatus("error");

    signOut("Sign up with email form error");
  };

  const addUser = () => {
    triggerMutation({
      onError: () => {
        handleError(FORM_GENERIC_ERROR_MESSAGE);
      },
      onCompleted: (data: Mutation["response"]) => {
        const onDataCompleted = async (data: Mutation["response"]) => {
          if (data && data?.createUser?.__typename !== "UserCreated") {
            if (data?.createUser?.__typename == "%other") {
              handleError(FORM_GENERIC_ERROR_MESSAGE);
            }
            if (data?.createUser?.__typename == "ForbiddenError") {
              handleError(data.createUser.reason);
            }
            return;
          }

          trackIntercomEvent(CustomerEngagementServiceEvents.Joined, {
            date: new Date(),
          });

          await auth?.currentUser?.getIdToken(true);
          refreshAuthToken();
          const userToken = await auth.currentUser?.getIdTokenResult();
          await identifyAmplitudeUser(
            (userToken?.claims?.["organisation"] as string) ?? "",
            (userToken?.claims?.["role"] as UserRoleEnum[]) ?? ["UNKNOWN"],
          );
          trackEvent("Signed up", {
            provider: "password",
            onboardingVersion: CURRENT_ONBOARDING_VERSION,
          });
          logger.info("useSignUpWithEmailForm", "User signed up correctly", {
            expiration: userToken?.claims?.exp,
            userId: userToken?.claims?.sub,
            authTime: userToken?.claims?.auth_time,
            role: userToken?.claims?.role,
          });

          if (invitation) {
            trackEvent("Invite accepted", {
              provider: "password",
              onboardingVersion: CURRENT_ONBOARDING_VERSION,
            });

            await push("/onboarding");
          }

          setStatus("success");
        };

        onDataCompleted(data);
      },
    });
  };

  const onFormSubmit = handleSubmit(async ({ password }) => {
    let hasTriedToFixStaleUser = false;
    await doOnSubmit();

    async function doOnSubmit() {
      if (!invitation || !invitation.email) {
        logger.warn("useSignUpWithEmailForm", "No invitation or email found", {
          invitation,
        });
        return;
      }
      try {
        const ssoVerifyEmail = await verifyEmail({ email: invitation.email });
        if (ssoVerifyEmail?.shouldStartSSOFlow) {
          toggleAuthMethod("sso-enforced")();
          setEmail(invitation.email);
          return;
        }

        setStatus("loading");
        const userToken = await auth.currentUser?.getIdTokenResult();
        if (!userToken) {
          await createUserWithEmailAndPassword(
            auth,
            invitation.email,
            password,
          );
        }
        addUser();
      } catch (error) {
        if (
          (error as any).code !== AuthErrorCodes.EMAIL_EXISTS ||
          hasTriedToFixStaleUser
        ) {
          throw error;
        }
        hasTriedToFixStaleUser = true;
        await fixInvitationStaleUser();
        setStatus("loading");
        await doOnSubmit();
      }
    }
  });

  return {
    status: loadingAcceptInvitation ? "pending" : "initial",
    data: {
      isButtonDisabled,
      formError,
      formInputProps: {
        register,
        errors: formState.errors,
      },
      isPasswordVisible,
      passwordValidation: {
        length: passwordLength,
        uppercase: passwordUppercase,
        symbol: passwordSymbol,
        number: passwordNumber,
      },
    },
    effects: {
      handleChangePassword,
      handleSubmit,
      onFormSubmit,
      togglePasswordVisibility,
    },
  };
}
