import { IconProps, SvgIcon } from "./SvgIcon";

export function GroupIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <circle
        cx="5.28592"
        cy="19.4204"
        r="2"
        transform="rotate(-135 5.28592 19.4204)"
        fill={color}
      />
      <path
        d="M13.2722 17.7649C11.8405 16.9581 10.4935 15.9452 9.27461 14.7263C8.05568 13.5073 7.04279 12.1604 6.23595 10.7287"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="18.7141"
        cy="4.57962"
        r="2"
        transform="rotate(45 18.7141 4.57962)"
        fill={color}
      />
      <path
        d="M10.7278 6.23508C12.1595 7.04191 13.5065 8.0548 14.7254 9.27373C15.9443 10.4927 16.9572 11.8396 17.764 13.2713"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="4.57882"
        cy="5.28671"
        r="2"
        transform="rotate(-45 4.57882 5.28671)"
        fill={color}
      />
      <circle
        cx="19.4196"
        cy="18.7141"
        r="2"
        transform="rotate(135 19.4196 18.7141)"
        fill={color}
      />
    </SvgIcon>
  );
}
