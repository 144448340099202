import { IconProps, SvgIcon } from "./SvgIcon";

export function SettingsIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M14 18.5H21M3 18.5H5M5 18.5C5 19.8807 6.11929 21 7.5 21C8.88071 21 10 19.8807 10 18.5C10 17.1193 8.88071 16 7.5 16C6.11929 16 5 17.1193 5 18.5ZM20 12.5H21M3 12.5H10M13 6.5H21M13 6.5C13 5.11929 11.8807 4 10.5 4C9.11929 4 8 5.11929 8 6.5C8 7.88071 9.11929 9 10.5 9C11.8807 9 13 7.88071 13 6.5ZM3 6.5H4M16.5 15C15.1193 15 14 13.8807 14 12.5C14 11.1193 15.1193 10 16.5 10C17.8807 10 19 11.1193 19 12.5C19 13.8807 17.8807 15 16.5 15Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
