import { IconProps, SvgIcon } from "./SvgIcon";

export function SunriseIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <line
        x1="2"
        y1="20"
        x2="22.2353"
        y2="20"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.1048 15.7059C21.456 14.7123 21.6471 13.6432 21.6471 12.5294C21.6471 7.26646 17.3806 3 12.1177 3C6.85472 3 2.58826 7.26646 2.58826 12.5294C2.58826 13.6432 2.77933 14.7123 3.1305 15.7059"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.8824 15.3943C17.3865 14.5576 17.6765 13.5774 17.6765 12.5294C17.6765 9.45935 15.1877 6.97058 12.1177 6.97058C9.04761 6.97058 6.55884 9.45935 6.55884 12.5294C6.55884 13.5774 6.84883 14.5576 7.35296 15.3943"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
