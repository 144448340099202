import { IconProps, SvgIcon } from "./SvgIcon";

export function PictureIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <rect
        x="21.8571"
        y="3"
        width="17"
        height="19.7143"
        rx="7"
        transform="rotate(90 21.8571 3)"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="15.7332"
        cy="8.81786"
        r="2"
        transform="rotate(-45 15.7332 8.81786)"
        fill={color}
      />
      <path
        d="M2.5 15.1191L4.47895 10.9813C5.74186 8.34063 9.45413 8.21372 10.8944 10.7619L12.3181 13.2808C12.9602 14.4167 14.1639 15.1191 15.4687 15.1191H21.0476"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
