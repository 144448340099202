import { IconProps, SvgIcon } from "./SvgIcon";

export function CloudLockIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M18.3636 15.1477C20.3719 15.1477 22 13.5197 22 11.5114C22 9.50307 20.3719 7.87502 18.3636 7.87502C18.3421 7.87502 18.3211 7.87521 18.2997 7.87558C17.8588 4.7914 15.2061 2.42047 12 2.42047C9.45759 2.42047 7.26381 3.91142 6.2445 6.06667C3.8746 6.22178 2 8.19303 2 10.6022C2 12.8861 3.68445 14.7767 5.87878 15.0993"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 16.0145C13.5 16.8429 12.8284 17.5145 12 17.5145C11.1716 17.5145 10.5 16.8429 10.5 16.0145C10.5 15.1861 11.1716 14.5145 12 14.5145C12.8284 14.5145 13.5 15.1861 13.5 16.0145Z"
        fill={color}
      />
      <path
        d="M14.7936 12.1171V11.4186C14.7936 9.87576 13.5429 8.625 12 8.625C10.4571 8.625 9.20634 9.87576 9.20634 11.4186V12.1171"
        stroke={color}
        strokeWidth="2"
      />
      <rect
        x="17.9841"
        y="11.7202"
        width="10.5714"
        height="11.9682"
        rx="5"
        transform="rotate(90 17.9841 11.7202)"
        stroke={color}
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
