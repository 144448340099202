import { IconProps, SvgIcon } from "./SvgIcon";

export function MoonIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M5.65681 5.65681C7.11244 4.20119 8.93661 3.34371 10.8247 3.08435C10.8073 3.10133 10.7901 3.11841 10.7729 3.1356C7.97603 5.93247 7.95678 10.462 10.7474 13.2526C13.538 16.0432 18.0675 16.024 20.8644 13.2271C20.8816 13.2099 20.8987 13.1927 20.9156 13.1753C20.6563 15.0634 19.7988 16.8876 18.3432 18.3432C14.8131 21.8733 9.11467 21.884 5.61533 18.3847C2.11599 14.8853 2.12674 9.18689 5.65681 5.65681Z"
        stroke={color}
        strokeWidth="2"
      />
      <circle cx="13.5" cy="6.5" r="1.5" fill={color} />
      <circle cx="19.5" cy="6.5" r="1.5" fill={color} />
      <circle cx="16.5" cy="10.5" r="1.5" fill={color} />
    </SvgIcon>
  );
}
