import { IconProps, SvgIcon } from "./SvgIcon";

export function PaperPlaneIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M16.1502 3.20109C17.4346 2.40711 19.1268 3.37692 19.0909 4.88712L18.7432 19.4901C18.6838 21.983 15.5646 23.1966 13.7726 21.2686L10.9041 18.1825C10.364 17.6014 9.66064 17.1953 8.88733 17.0181L4.6987 16.0582C2.14189 15.4724 1.62465 12.1803 3.73867 10.8735L16.1502 3.20109Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M10.1603 17.0622L14.4728 9.59271"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
