import {
  Context,
  createContext,
  useContext,
  useMemo,
  useState,
  ReactNode,
} from "react";

import useLoginReturningFromAuthServer from "../../hooks/useLoginReturningFromAuthServer";
import useLoginWithCustomToken from "../../hooks/useLoginWithCustomToken";

interface Props {
  children: ReactNode;
}

interface IAuthAuthorizedContext {
  status: AuthAuthorizedType;
  setStatus: (status: AuthAuthorizedType) => void;
  errorMessage?: string | null;
  setErrorMessage: (errorMessage: string | null) => void;
  setTenant?: (tenant: string) => void;
  tenant?: string | null;
}

export type AuthAuthorizedType =
  | "loading"
  | "success"
  | "in_progress"
  | "error";

const AuthAuthorizedContext: Context<IAuthAuthorizedContext> = createContext({
  status: "success" as AuthAuthorizedType,
  setStatus: (_status: AuthAuthorizedType) => {},
  setErrorMessage: (_errorMessage: string | null) => {},
});

export const useAuthAuthorized = (): IAuthAuthorizedContext =>
  useContext(AuthAuthorizedContext);

export default function AuthAuthorizedContextProvider({
  children,
}: Props): JSX.Element {
  const [status, setStatus] = useState<AuthAuthorizedType>("success");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [tenant, setTenant] = useState<string | null>(null);

  const value = useMemo(
    () => ({
      status,
      setStatus,
      errorMessage,
      setErrorMessage,
      tenant,
      setTenant,
    }),
    [status, errorMessage, tenant],
  );

  useLoginReturningFromAuthServer(setStatus);
  useLoginWithCustomToken(setStatus);

  return (
    <AuthAuthorizedContext.Provider value={value}>
      {children}
    </AuthAuthorizedContext.Provider>
  );
}
