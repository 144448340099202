import cx from "classnames";
import { useEffect, useState } from "react";

import { ArrowIcon } from "../../atoms/Icons";
import Selectable from "../../atoms/Selectable";
import Button from "../../atoms/Button";
import Select, { SelectItem } from "../../molecules/Select";
import * as s from "./styles";

const DEFAULT_MIN_PER_PAGE = 10;

enum Direction {
  Next = "next",
  Prev = "prev",
}

interface Props {
  currentPage: number;
  paginationType: string;
  totalCount: number;
  paginationHandler: (type: Direction) => void;
  handleGoToPage?: (event: number) => void;
  changePerPage?: (pageSize: number) => void;
  pageSize?: number;
  showPages?: boolean;
}

export default function Pagination({
  currentPage,
  paginationType,
  totalCount,
  paginationHandler,
  handleGoToPage,
  changePerPage,
  showPages = false,
  pageSize = DEFAULT_MIN_PER_PAGE,
}: Props): JSX.Element {
  const [perPage, setPerPage] = useState(pageSize);
  const [pages, setPages] = useState(Math.ceil(totalCount / perPage));

  const isFirstPage = currentPage === 1;

  const showingLabelFrom = isFirstPage
    ? currentPage
    : perPage * (currentPage - 1) + 1;

  const showingLabelTo = () => {
    if (perPage * currentPage < totalCount) {
      return perPage * currentPage;
    }
    if (isFirstPage && perPage * currentPage < totalCount) {
      return perPage;
    }
    return totalCount;
  };

  useEffect(() => {
    setPages(Math.ceil(totalCount / perPage));
  }, [totalCount, perPage]);

  useEffect(() => {
    if (changePerPage) {
      changePerPage(perPage);
    }
  }, [perPage, changePerPage]);

  const perPageOptions: SelectItem[] = [
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const handlePerPageChanges = (value: string | number) => {
    {
      const numberValue = parseInt(value.toString());
      const newPerPage = numberValue;
      setPerPage(newPerPage);
      setPages(Math.ceil(totalCount / newPerPage));
    }
  };

  const hasGoToSpecificPageAction = Boolean(handleGoToPage);

  const directionButton = (direction: Direction) => {
    const isPrevDirection = direction === Direction.Prev;
    const isNextDirection = direction === Direction.Next;

    const isDisabled = currentPage === (isPrevDirection ? 1 : pages);

    return (
      <div className="flex w-10">
        <Button
          variant="icon"
          iconLeft={
            isPrevDirection ? <ArrowIcon direction="left" /> : undefined
          }
          iconRight={
            isNextDirection ? <ArrowIcon direction="right" /> : undefined
          }
          disabled={isDisabled}
          size="xs"
          width="full"
          onClick={() => paginationHandler(direction)}
        >
          <span className="sr-only">
            {isPrevDirection ? "Previous" : "Next"}
          </span>
        </Button>
      </div>
    );
  };

  return (
    <div className={cx(s.wrapper)}>
      <div className={cx(s.container)}>
        <div className="flex w-full justify-between">
          <div className={cx(s.rowsInfo)}>
            <div className="flex"> Rows per page</div>
            <div className="flex h-8 items-center">
              <Select
                id="pagination-rows-per-page"
                size="small"
                preSelectedItem={
                  {
                    value: perPage,
                    label: perPage.toString(),
                  } as unknown as SelectItem
                }
                items={perPageOptions}
                onChange={({ value }) => handlePerPageChanges(value)}
              />
            </div>
          </div>
          <div className="flex-end self-center text-sm text-neutral-600">
            Showing <span>{showingLabelFrom}</span> -{" "}
            <span>{showingLabelTo()}</span> out of <span>{totalCount}</span>{" "}
            {paginationType}
          </div>
        </div>
      </div>

      <div className="mt-4 flex w-full items-center ">
        <div className="flex w-full items-center justify-center">
          <nav className={cx(s.navbar)} aria-label="pagination nav">
            <div className="flex ">{directionButton(Direction.Prev)}</div>
            {showPages &&
              Array.from({ length: pages }).map((_, i) => {
                const currentPageIndex = i + 1;
                const isSelected = currentPage === currentPageIndex;
                return (
                  <button
                    key={`pagination_${currentPageIndex}`}
                    className={`flex w-12 self-center text-center ${
                      !hasGoToSpecificPageAction && "cursor-not-allowed"
                    }`}
                    disabled={hasGoToSpecificPageAction}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!handleGoToPage) {
                        return;
                      }

                      handleGoToPage(currentPageIndex);
                    }}
                  >
                    <Selectable
                      variant={isSelected ? "primary" : "neutral"}
                      label={currentPageIndex.toString()}
                      aria-current={isSelected ? "page" : undefined}
                      aria-label={isSelected ? "selected" : "not-selected"}
                    />
                  </button>
                );
              })}
            <div className="flex">{directionButton(Direction.Next)}</div>
          </nav>
        </div>
      </div>
    </div>
  );
}
