import cx from "classnames";
import { ExclamationIcon, Text } from "@olivahealth/oli-ui";
import tokens from "../../../theme/tokens";

type Variant = "warning";

interface Props {
  description: string;
  heading: string;
  variant: Variant;
}

const variantClasses = {
  warning: "text-status-yellow-700",
};

const iconColours = {
  warning: tokens.colors["status-yellow"][700],
};

const icons = {
  warning: (
    <ExclamationIcon variant="triangle" color={iconColours["warning"]} />
  ),
};

export default function NotificationMessage({
  description,
  heading,
  variant,
}: Props): JSX.Element {
  return (
    <div className={cx("mb-6 flex flex-col", variantClasses[variant])}>
      <div className="flex items-center">
        <div className="flex w-full items-center gap-x-2">
          {icons[variant]}
          <Text weight="bold">{heading}</Text>
        </div>
      </div>
      <div className="ml-8">
        <Text>{description}</Text>
      </div>
    </div>
  );
}
