export enum CustomerEngagementServiceEvents {
  AttendedSession = "User attended a session",
  AttendsClass = "User attends a class",
  AttendsTalk = "User attends a talk",
  BookedSession = "User booked a session",
  FirstAppOpen = "First app open",
  Joined = "Joined",
  MatchingSurveyCompleted = "User completed matching survey",
  SelfGuidedContentStarted = "User started consuming self-guided content",
  WellbeingProfileCreated = "User created their wellbeing profile",
  WellbeingProfileStarted = "User started creating their wellbeing profile",
}
