import { useRouter } from "next/router";
import { ArrowIcon, Button, Text, ToastContainer } from "@olivahealth/oli-ui";
import { ReactNode } from "react";

import useTranslation from "../../../hooks/useTranslation";
import * as s from "./styles";

export type PageLayoutVariant = "default" | "full-width";

interface Props {
  children: ReactNode;
  rightSideContent?: ReactNode;
  showBackButton?: boolean;
  backButtonText?: string;
  subtitle?: ReactNode | string;
  surtitle?: string;
  title?: string;
  variant?: PageLayoutVariant;
}

/**
 * The page template is a basic 1 column layout with centered content
 * It's used in pages that don't need a sidebar on the right side
 */
export default function PageLayout({
  children,
  showBackButton = false,
  backButtonText,
  rightSideContent,
  subtitle,
  surtitle,
  title,
  variant = "default",
}: Readonly<Props>) {
  const { t } = useTranslation("common");
  const { back } = useRouter();

  const showHeader = Boolean(title) || Boolean(subtitle);

  return (
    <div className={s.page(variant)}>
      <ToastContainer limit={3} />
      <div className={s.container(variant)}>
        {(showBackButton || backButtonText) && (
          <div className="mb-9">
            <Button
              aria-label={t("backLinkAriaLabel")}
              iconLeft={<ArrowIcon direction="left" />}
              onClick={back}
              variant="icon"
            >
              {backButtonText ?? t("backLink")}
            </Button>
          </div>
        )}
        {showHeader ? (
          <header className="mb-8 flex items-start justify-between md:mb-16">
            <div className="flex flex-col">
              {surtitle && (
                <div className="mb-2">
                  <Text variant="h3" color="secondary">
                    {surtitle}
                  </Text>
                </div>
              )}
              {title && (
                <Text
                  variant={!showBackButton ? "h1" : "h2"}
                  component="h1"
                  gutter="sm"
                  weight="bold"
                >
                  {title}
                </Text>
              )}
              {subtitle && (
                <Text
                  variant={!showBackButton ? "h3" : "body"}
                  component="h2"
                  color="secondary"
                  size={showBackButton ? "xl" : undefined}
                >
                  {subtitle}
                </Text>
              )}
            </div>
            {rightSideContent && <div>{rightSideContent}</div>}
          </header>
        ) : null}
        <div className="animate-fade-in">{children}</div>
      </div>
    </div>
  );
}
