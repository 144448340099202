import { useEffect } from "react";
import { Transition } from "@headlessui/react";
import { useIntercom } from "react-use-intercom";
import SubmissionStatus from "@olivahealth/graphql-server/src/domain/value-objects/TriagingSubmissionStatus";
import SurveyTitles from "@olivahealth/graphql-server/src/domain/value-objects/SurveyTitles";
import {
  AlertBanner,
  AlertBannerIconColors,
  CalendarIcon,
  Card,
  CheckCircleIcon,
  CrossIcon,
  Text,
  Button,
} from "@olivahealth/oli-ui";
import tokens from "@olivahealth/oli-ui/theme/tokens";
import { EMPLOYEE_REVIEW_LINK } from "@olivahealth/constants";

import NativeSurvey from "../../../../components/common/NativeSurvey";
import useTranslation from "../../../../hooks/useTranslation";
import { useSharedStoreContext } from "../../../../services/contexts/SharedStoreContext";
import useEmployeeNPSSurvey from "./useEmployeeNPSSurvey";

export default function EmployeeNPSSurvey(): JSX.Element | null {
  const { t } = useTranslation("surveys", {
    keyPrefix: "employeeNPSSurvey",
  });

  const {
    data: {
      formInitialState,
      formStatus,
      pendingSubmissionId,
      surveyFlowToEventsMapping,
      variables,
      willLeaveReview,
    },
    effects: {
      onAnswersChanged,
      onDismissButtonClick,
      onSubmitSuccess,
      closeSurvey,
    },
  } = useEmployeeNPSSurvey();

  const renderSurveyFlowContent = () => {
    switch (formStatus) {
      case "OPENED":
        return (
          <Card border={false} padding="lg" rounding="xl">
            <NativeSurvey
              dismissOnlyOnFirstQuestion
              dismissButtonLabel={t("dismissButtonLabel")}
              formInitialState={formInitialState}
              onAnswersChanged={onAnswersChanged}
              onDismissButtonClick={onDismissButtonClick}
              onSubmitSuccess={onSubmitSuccess}
              submissionId={pendingSubmissionId}
              submissionStatus={SubmissionStatus.COMPLETED}
              surveyFlowToEventsMapping={surveyFlowToEventsMapping}
              title={SurveyTitles.EMPLOYEE_NPS}
              variables={variables}
              variant="embed"
              singleChoiceRendererVariant="secondary"
            />
          </Card>
        );
      case "DISMISSED":
        return (
          <AlertBanner
            icon={
              <CalendarIcon
                variant="check"
                color={AlertBannerIconColors["info"]}
              />
            }
            variant="info"
            heading={t("dismissedMessage")}
          />
        );
      case "COMPLETED": {
        if (willLeaveReview) {
          return (
            <Card border={false} padding="lg" rounding="xl">
              <div className="flex justify-between mb-4 items-center">
                <div className="flex gap-x-2">
                  <CheckCircleIcon
                    color={tokens.colors["status-green"][600]}
                    onClick={closeSurvey}
                  />
                  <Text weight="bold" color="success-dark">
                    {t("successMessage")}
                  </Text>
                </div>
                <Button
                  variant="icon"
                  onClick={closeSurvey}
                  iconLeft={<CrossIcon />}
                />
              </div>
              <Text>{t("successLeaveReview")}</Text>
              <div className="flex mt-8 justify-center">
                <Button
                  onClick={() => {
                    closeSurvey();
                    window.open(EMPLOYEE_REVIEW_LINK, "_blank");
                  }}
                >
                  {t("leaveReviewButton")}
                </Button>
              </div>
            </Card>
          );
        }

        return null;
      }
      default:
        throw Error(`Invalid form status: ${formStatus}`);
    }
  };

  const { update } = useIntercom();
  const { setSharedStore, surveyEmployeeNPS } = useSharedStoreContext();

  const attemptToShowSurvey = formStatus !== "CLOSED";
  const hasSeenSurvey = attemptToShowSurvey && pendingSubmissionId;

  useEffect(() => {
    if (hasSeenSurvey && !surveyEmployeeNPS.hasSeenSurvey) {
      setSharedStore({
        surveyEmployeeNPS: {
          ...surveyEmployeeNPS,
          hasSeenSurvey: true,
        },
      });
    }

    const hasSeenNativeEmployeeNPSSurvey = hasSeenSurvey ? "true" : "false";
    update({
      customAttributes: {
        hasSeenNativeEmployeeNPSSurvey,
      },
    });
  }, [hasSeenSurvey, update, setSharedStore]);

  if (!attemptToShowSurvey) {
    return null;
  }

  return (
    <div className="mb-8">
      <Transition
        as="div"
        leave="transition-all delay-[5000ms] duration-1000 overflow-hidden"
        leaveFrom="transform scale-100 opacity-100 max-h-96"
        leaveTo="transform scale-95 opacity-0 max-h-0"
        show={formStatus === "OPENED" || formStatus === "COMPLETED"}
      >
        <div className="max-w-[38rem]">{renderSurveyFlowContent()}</div>
      </Transition>
    </div>
  );
}
