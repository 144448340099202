import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRelayEnvironment } from "react-relay";
import { fetchQuery, graphql, Subscription } from "relay-runtime";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { useSigninCheck } from "@olivahealth/firebase/client";
import { ORGANISATION_ID_OLIVANS } from "@olivahealth/constants";
import { useGrowthBook } from "@growthbook/growthbook-react";

import {
  isSurveyNPSDismissed,
  resetLocalStorageDismissedAt,
} from "../../utils/survey/nps-helper";
import { useAuthAuthorized } from "./AuthAuthorizedContext";
import { useSharedStoreContext } from "./SharedStoreContext";
import {
  UserDataContextQuery,
  UserDataContextQuery$data,
} from "./__generated__/UserDataContextQuery.graphql";

type UserDataContextStatus = "initial" | "loading" | "error" | "success";
const env = process.env.NEXT_PUBLIC_ENVIRONMENT || "local";

export interface IUserDataContext {
  data?: UserDataContextQuery$data["viewer"] | null;
  status: UserDataContextStatus;

  refetchData(): Promise<void>;
}

const UserDataQuery = graphql`
  query UserDataContextQuery {
    viewer {
      ... on User {
        sessionPriceString
        dnaSessionPriceString
        guestInformation {
          dependeeName
          dependeeSurname
          dependeeEmail
          status
          switchToPersonalPlanScheduledFor
        }
        organisation {
          id
          hasTeamSessionsOnly
          name
          teams
          plan {
            type
            maxNumberOfOneToOneSessions
            maxNumberOfOneToOneSessionsForGuests
            maxNumberOfGuestsPerUser
            maxNumberOfPublicClasses
            maxNumberOfWorkshops
            oneToOneSessionsRenewalPeriod
            oneToOneSessionsForGuestsRenewalPeriod
            endDate
            oneToOneRenewalDate
            publicClassesRenewalDate
            services
            hasDNAChargeEnabled
            name
            status
            accessCareerCoaching
          }
          settings {
            crisisSupportEnabled
          }
        }
        hasBookedSessions
        sessionAllowance {
          oneToOneSessions
          coachingSessions
          therapySessions
          publicClassSessions
          workshopSessions
          activeCareSessions
        }
        hasActiveCoaching
        hasActiveTherapy
        createdAt
        role
        languages
        email
        id
        name
        verificationHash
        personalEmail
        paymentServicePaymentMethodId
        surname
        organisationTeam
        sendbirdId
        hasEnrolledMfa
        surveyEmployeeNPSPendingSubmissionId
        surveyProfessionalNPSPendingSubmissionId
        vipTrialSurveySubmissions {
          gettingStartedSurveySubmission {
            status
            submissionId
          }
          followUpSurveySubmission {
            status
            submissionId
          }
        }
        gender
        status
        city
        country
        region
        preferredLanguage
        marketingOptIn
        phone
        dateOfBirth
        apartment
        streetAddress
        timezone
        zipCode
        settings {
          allowDataProcessing
          hasGivenDataProcessingConsent
          hasGivenSessionSummarisationConsent
        }
        careTypesAvailable {
          careType
          isRecommended
        }
        deviceTokens {
          token
        }
        religion
        sexualOrientation
        ethnicity
        maxNumberOfServices {
          hasUnlimitedSessions
          hasUnlimitedCoachingSessions
          hasUnlimitedTherapySessions
          hasSplitAllocation
          hasTherapyOnlyAllocation
          hasCoachingOnlyAllocation
          numberOfWorkshops
          numberOfOneToOneSessions
          numberOfPublicClasses
          numberOfTherapySessions
          numberOfCoachingSessions
          numberOfSessionsForActiveCare
        }
      }
    }
  }
`;

const UserDataContext = createContext<IUserDataContext>({
  data: null,
  status: "initial",
  refetchData: () => Promise.resolve(),
});
export const useUserData = (): IUserDataContext => useContext(UserDataContext);

export default function UserDataProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [data, setData] = useState<IUserDataContext["data"]>(null);
  const environment = useRelayEnvironment();
  const [status, setStatus] = useState<UserDataContextStatus>("initial");
  const { data: signInCheckResult } = useSigninCheck();
  const { status: authAuthorizedStatus } = useAuthAuthorized();
  const gb = useGrowthBook();
  const querySubscriptionRef = useRef<Subscription | null>(null);
  const { setSharedStore } = useSharedStoreContext();

  const fetchData = useCallback(() => {
    if (querySubscriptionRef.current) {
      querySubscriptionRef.current.unsubscribe();
    }

    const queryObservable = fetchQuery<UserDataContextQuery>(
      environment,
      UserDataQuery,
      {},
      {
        fetchPolicy: "network-only",
      },
    );

    return new Promise<void>((resolve, reject) => {
      querySubscriptionRef.current = queryObservable.subscribe({
        start: (): void => {
          setStatus("loading");
        },
        next: (data) => {
          try {
            const isOliva = ["oliva", ORGANISATION_ID_OLIVANS].includes(
              data.viewer.organisation?.id ?? "",
            );
            const hidePersonalInfo = env === "production" && isOliva;
            const user = {
              id: data.viewer.id,
              name: hidePersonalInfo ? data.viewer.name : undefined,
              email: hidePersonalInfo ? data.viewer.email : undefined,
              organisation: data.viewer.organisation?.name,
              role: data.viewer.role,
            };
            datadogRum.setUser(user);
            datadogLogs.setUser(user);
            gb?.setAttributes({
              id: user.id,
              company: data.viewer.organisation?.id,
            });
          } catch {
            console.error(
              "[UserDataContext] Error setting user data to datadog.",
            );
          }

          const surveyEmployeeNPSNotDismissed =
            !isSurveyNPSDismissed("employee");
          const surveyProfessionalNPSNotDismissed =
            !isSurveyNPSDismissed("professional");

          setSharedStore({
            ...(surveyEmployeeNPSNotDismissed
              ? {
                  surveyEmployeeNPS: {
                    pendingSubmissionId:
                      data?.viewer.surveyEmployeeNPSPendingSubmissionId ?? null,
                  },
                }
              : {}),
            ...(surveyProfessionalNPSNotDismissed
              ? {
                  surveyProfessionalNPS: {
                    pendingSubmissionId:
                      data?.viewer.surveyProfessionalNPSPendingSubmissionId ??
                      null,
                  },
                }
              : {}),
          });

          if (surveyEmployeeNPSNotDismissed) {
            resetLocalStorageDismissedAt("employee");
          }
          if (surveyProfessionalNPSNotDismissed) {
            resetLocalStorageDismissedAt("professional");
          }

          setData(data.viewer);
          setStatus("success");
          resolve();
        },
        error: (error): void => {
          setStatus("error");
          reject(error);
        },
      });
    });
  }, [environment, querySubscriptionRef]);

  useEffect(() => {
    if (!signInCheckResult?.signedIn || authAuthorizedStatus !== "success") {
      return;
    }

    fetchData();
  }, [signInCheckResult, authAuthorizedStatus, fetchData]);

  const value = {
    data,
    status,
    refetchData: fetchData,
  };

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
}
