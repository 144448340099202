import { IconProps, SvgIcon } from "./SvgIcon";

export function MeditationIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <circle cx="12" cy="4.82806" r="2" fill={color} />
      <path
        d="M7.12073 12C7.38075 11.7403 7.66139 11.5013 7.95997 11.2856C9.09533 10.4652 10.4901 9.98181 11.9979 9.98181C13.9021 9.98181 15.6262 10.753 16.875 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22.5879 17.0068C19.6039 19.1422 15.9482 20.3989 11.999 20.3989C8.04985 20.3989 4.39419 19.1422 1.41016 17.0068"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
