import ctl from "@netlify/classnames-template-literals";
import type { DropdownSize } from "./index";

export const buttonWrapper = (size: DropdownSize) =>
  ctl(`
  flex 
  w-full
  justify-between
  gap-x-4 
  rounded-lg 
  border 
  border-neutral-300 
  ${size === "sm" ? "p-1.5" : "p-4"}
  hover:bg-neutral-50
`);

export const optionsWrapper = ctl(`
  border-oli-gray-500
  max-h-screen
  overflow-y-auto
  mt-2 
  flex 
  flex-col 
  rounded-lg border
  bg-white
`);

export const optionWrapper = ctl(`
  flex 
  cursor-pointer 
  items-center 
  gap-x-3 
  p-4
`);
