import { IconProps, SvgIcon } from "./SvgIcon";

export function GlobeIcon({
  className,
  color = "currentColor",
  fill,
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon fill={fill} size={size} className={className}>
      <path
        d="M8 12H16M8 12C8 16.9706 9.79086 21 12 21M8 12C8 7.02944 9.79086 3 12 3M8 12H5.5M16 12C16 7.02944 14.2091 3 12 3M16 12C16 16.9706 14.2091 21 12 21M16 12H18.5M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
