import React, { Dispatch } from "react";
import Downshift from "downshift";
import { ChevronIcon, CrossIcon, DropdownOption } from "@olivahealth/oli-ui";
import * as s from "./styles";

export interface Props {
  options: Array<DropdownOption<string>>;
  placeholder?: string;
  setSelected: Dispatch<DropdownOption<string> | null>;
}

export default function SearchListbox({
  options,
  placeholder,
  setSelected,
}: Props): JSX.Element {
  return (
    <Downshift
      onChange={setSelected}
      itemToString={(item) => (item ? item.value : "")}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        inputValue,
        selectedItem,
        isOpen,
        openMenu,
        clearSelection,
      }) => (
        <div>
          <div className={s.inputBar}>
            <input
              {...getInputProps()}
              onClick={() => openMenu()}
              className="h-8 w-full bg-transparent outline-none border-0"
              placeholder={placeholder ?? ""}
            />
            {selectedItem ? (
              <button
                onClick={() => clearSelection()}
                aria-label="clear selection"
              >
                <CrossIcon />
              </button>
            ) : (
              <button {...getToggleButtonProps()}>
                <ChevronIcon
                  direction="down"
                  className="mr-2 h-5 w-5"
                  aria-hidden="true"
                  size={20}
                />
              </button>
            )}
          </div>
          <div className="relative hover:bg-blue-500">
            <ul {...getMenuProps()} className={s.optionsList} hidden={!isOpen}>
              {isOpen &&
                filteredOptions(options, inputValue).map((option, index) => (
                  <li
                    className={s.option}
                    key={`${option.value}${index}`}
                    {...getItemProps({
                      item: option,
                      index,
                    })}
                    dangerouslySetInnerHTML={{ __html: option.label }}
                  />
                ))}
            </ul>
          </div>
        </div>
      )}
    </Downshift>
  );
}

function filteredOptions(
  options: Array<DropdownOption<string>>,
  inputValue: string | null,
) {
  return options.filter(
    (option) =>
      !inputValue ||
      option.value
        .toLocaleLowerCase()
        .includes(inputValue.toLocaleLowerCase()) ||
      (typeof option.label === "string" &&
        option.label
          .toLocaleLowerCase()
          .includes(inputValue.toLocaleLowerCase())),
  );
}
