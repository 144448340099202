import { LockIcon, Text } from "@olivahealth/oli-ui";
import tokens from "@olivahealth/oli-ui/theme/tokens";

import Trans from "../../../rebrand/molecules/Trans";
import * as s from "./styles";
import { NativeSurveyVariables } from "./index";

export function NativeSurveyFooter({
  notice,
  variables,
}: {
  notice?: string | null;
  variables: NativeSurveyVariables;
}): JSX.Element | null {
  if (!notice) {
    return null;
  }

  return (
    <footer>
      <div className={s.footerNoticeWrapper}>
        <LockIcon color={tokens.colors.neutral[600]} variant="check" />
        <Text color="secondary" size="sm">
          <Trans i18nKey={notice} values={variables} />
        </Text>
      </div>
    </footer>
  );
}
