import { IconProps, SvgIcon } from "./SvgIcon";

interface Props extends IconProps {
  direction: "up" | "down" | "left" | "right";
}

export function ChevronCircleIcon({
  className,
  color = "currentColor",
  direction,
  size,
}: Props): JSX.Element {
  const chevron = {
    left: (
      <path
        d="M12.75 9L10.5993 10.5486C9.4669 11.364 9.4669 12.636 10.5993 13.4514L12.75 15"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    ),
    right: (
      <path
        d="M10.75 15L12.9007 13.4514C14.0331 12.636 14.0331 11.364 12.9007 10.5486L10.75 9"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    ),
    up: (
      <path
        d="M15 13L13.4514 10.8493C12.636 9.7169 11.364 9.7169 10.5486 10.8493L9 13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    ),
    down: (
      <path
        d="M9 10.5L10.5486 12.6507C11.364 13.7831 12.636 13.7831 13.4514 12.6507L15 10.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" />
      {chevron[direction]}
    </SvgIcon>
  );
}
