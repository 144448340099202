import { IconProps, SvgIcon } from "./SvgIcon";

export function OpenIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M10.5 5H8.45455C5.44208 5 3 7.44208 3 10.4545V14.5455C3 17.5579 5.44208 20 8.45455 20H12.5455C15.5579 20 18 17.5579 18 14.5455V12.125"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19.03 8.81763L18.9792 7.14023C18.9312 5.55465 17.657 4.28052 16.0714 4.23247L14.394 4.18164"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10.7296 12.4821L17.8028 5.40882"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
