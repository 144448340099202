import { ReactNode } from "react";
import cx from "classnames";
import Text, { TextColor, TextSize } from "../../atoms/Text";
import * as s from "./styles";

type CardPadding = "sm" | "md";

interface CommonProps {
  backgroundColorClassName?: string;
  button?: ReactNode;
  fullHeight?: boolean;
  image?: ReactNode;
  padding?: CardPadding;
  textColor?: TextColor;
  textSize?: TextSize;
  titleTextColor?: TextColor;
}

type Props =
  | (CommonProps & {
      title: string;
      description?: string | ReactNode;
    })
  | (CommonProps & {
      title?: string;
      description: string | ReactNode;
    });

function getCardPadding(padding: CardPadding): string {
  const cardPadding = {
    sm: "p-2 lg:p-4",
    md: "p-4 lg:p-6",
  };

  return cardPadding[padding];
}

export default function CardSecondary({
  backgroundColorClassName = "bg-neutral-50",
  button,
  description,
  fullHeight = false,
  image,
  padding,
  textColor,
  textSize,
  title,
  titleTextColor,
}: Props): JSX.Element {
  const cardPadding = getCardPadding(padding ?? "md");

  return (
    <div
      className={cx(s.cardSecondary, cardPadding, {
        [backgroundColorClassName]: Boolean(backgroundColorClassName),
        "h-full": fullHeight,
        "items-start": fullHeight,
      })}
    >
      <div className={s.contentWrapper}>
        {image && <span className={s.imageWrapper}>{image}</span>}
        <div className={s.content}>
          {title && (
            <Text variant="h3" gutter="sm" color={titleTextColor}>
              {title}
            </Text>
          )}
          {description && (
            <Text color={textColor ?? "secondary"} size={textSize ?? "base"}>
              {description}
            </Text>
          )}
        </div>
      </div>
      {button && <div className={s.buttonWrapper}>{button}</div>}
    </div>
  );
}
