import { IconProps, SvgIcon } from "./SvgIcon";

export function RoseIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6631 12C21.0766 11.0046 22 9.36016 22 7.5C22 4.46243 19.5376 2 16.5 2C14.6398 2 12.9954 2.92345 12 4.33692C11.0046 2.92345 9.36016 2 7.5 2C4.46243 2 2 4.46243 2 7.5C2 9.36016 2.92345 11.0046 4.33692 12C2.92345 12.9954 2 14.6398 2 16.5C2 19.5376 4.46243 22 7.5 22C9.36016 22 11.0046 21.0766 12 19.6631C12.9954 21.0766 14.6398 22 16.5 22C19.5376 22 22 19.5376 22 16.5C22 14.6398 21.0766 12.9954 19.6631 12ZM10.7242 17.8641C10.1925 19.1194 8.94908 20 7.5 20C5.567 20 4 18.433 4 16.5C4 15.0509 4.88063 13.8075 6.13592 13.2758C6.63173 15.5655 8.43452 17.3683 10.7242 17.8641ZM10.9413 15.8584C11.2785 15.9507 11.6335 16 12 16C12.3665 16 12.7215 15.9507 13.0587 15.8584C14.4169 15.4866 15.4866 14.4169 15.8584 13.0587C15.9507 12.7215 16 12.3665 16 12C16 11.6335 15.9507 11.2785 15.8584 10.9413C15.4866 9.58315 14.4169 8.51341 13.0587 8.1416C12.7215 8.04929 12.3665 8 12 8C11.6335 8 11.2785 8.04929 10.9413 8.1416C9.58315 8.51341 8.51341 9.58315 8.1416 10.9413C8.04929 11.2785 8 11.6335 8 12C8 12.3665 8.04929 12.7215 8.1416 13.0587C8.51341 14.4169 9.58315 15.4866 10.9413 15.8584ZM6.13592 10.7242C6.63173 8.43452 8.43452 6.63173 10.7242 6.13592C10.1925 4.88063 8.94908 4 7.5 4C5.567 4 4 5.567 4 7.5C4 8.94908 4.88063 10.1925 6.13592 10.7242ZM13.2758 6.13592C13.8075 4.88063 15.0509 4 16.5 4C18.433 4 20 5.567 20 7.5C20 8.94908 19.1194 10.1925 17.8641 10.7242C17.3683 8.43452 15.5655 6.63173 13.2758 6.13592ZM17.8641 13.2758C17.3683 15.5655 15.5655 17.3683 13.2758 17.8641C13.8075 19.1194 15.0509 20 16.5 20C18.433 20 20 18.433 20 16.5C20 15.0509 19.1194 13.8075 17.8641 13.2758Z"
        fill={color}
      />
    </SvgIcon>
  );
}
