import { IconProps, SvgIcon } from "./SvgIcon";

export function BriefCaseIcon({
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size}>
      <g clipPath="url(#clip0_198_7319)">
        <path
          d="M15.7988 5.5C19.6648 5.5 22.7988 8.63401 22.7988 12.5V15.5C22.7988 19.366 19.6648 22.5 15.7988 22.5H9.79883C5.93284 22.5 2.79883 19.366 2.79883 15.5L2.79883 12.5C2.79883 8.63401 5.93284 5.5 9.79883 5.5L15.7988 5.5Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M21.7988 10.5C21.319 10.7573 20.8011 10.9942 20.2501 11.208C18.155 12.0209 15.5811 12.5 12.7988 12.5C9.28485 12.5 6.10324 11.7358 3.79883 10.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle cx="12.7988" cy="13.5" r="2" fill={color} />
        <path
          d="M16.7988 6.5V5.5C16.7988 3.29086 15.008 1.5 12.7988 1.5V1.5C10.5897 1.5 8.79883 3.29086 8.79883 5.5V6.5"
          stroke={color}
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_198_7319">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.798828 0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
