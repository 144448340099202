import { IconProps, SvgIcon } from "./SvgIcon";

export function DownloadIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M22 17.5C19.1819 19.3885 15.7296 20.5 12 20.5C8.27044 20.5 4.81808 19.3885 2 17.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8 13.5L10.2 15.15C11.2667 15.95 12.7333 15.95 13.8 15.15L16 13.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 3.5V15.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
