import cx from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import useClickOutside from "@olivahealth/utils/reactHooks/useClickOutside";
import * as s from "../../shared/inputStyles";
import { ChevronIcon } from "../../atoms/Icons";
import Text from "../../atoms/Text";
import Checkbox from "../../molecules/Checkbox";
import Divider from "../../atoms/Divider";

export interface SelectItem {
  label: string;
  value: string;
  count?: number;
  groupColor?: string;
}

export interface OptionItem extends SelectItem {
  checked?: boolean;
}

export interface GroupedItems {
  [key: string]: OptionItem[];
}

export interface Props {
  disabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  id: string;
  items?: SelectItem[];
  groupedItems?: GroupedItems;
  preText?: string;
  label?: string;
  onChange?: (items: string[]) => void;
  placeholder?: string;
  list?: string[];
  size?: string;
  required?: boolean;
  labelInfoIcon?: React.ReactElement;
  hideCounts?: boolean;
  hideAllOption?: boolean;
  dataTestId?: string;
}

export default function MultiSelect({
  disabled = false,
  hasError = false,
  helperText,
  preText,
  size,
  id,
  items,
  label,
  onChange,
  list = [],
  required = false,
  labelInfoIcon,
  placeholder,
  hideCounts,
  groupedItems,
  hideAllOption,
  dataTestId,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedList, setSelectedList] = useState<string[]>(list);
  const [options, setOptions] = useState<OptionItem[]>([]);
  const [groupedOptions, setGroupedOptions] = useState<GroupedItems>({});

  const flattenedGroupedItems = groupedItems
    ? Object.values(groupedItems).reduce((acc, item) => {
        acc = [...acc, ...item];
        return acc;
      }, [])
    : undefined;

  useEffect(() => {
    setSelectedList(list);

    if (items) {
      const updatedOptions = items.map((item: SelectItem) => ({
        ...item,
        checked: list.includes(item.value),
      }));
      setOptions(updatedOptions);
    }

    if (groupedItems) {
      const updatedOptions = Object.keys(groupedItems).reduce(
        (acc, itemKey) => {
          const items = groupedItems[itemKey];
          acc = {
            ...acc,
            [itemKey]: items.map((item: SelectItem) => ({
              ...item,
              checked: list.includes(item.value),
            })),
          };
          return acc;
        },
        {},
      );
      setGroupedOptions(updatedOptions);
    }
  }, [list]);

  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOnChange = (val: string, checked: boolean) => {
    if (checked) {
      if (val === "all") {
        if (items) {
          const newList = items.map((item: SelectItem) => item.value);
          setSelectedList(newList);
          onChange?.(newList);
          return;
        }

        if (groupedItems && flattenedGroupedItems) {
          const newList = flattenedGroupedItems.map(
            (item: SelectItem) => item.value,
          );
          setSelectedList(newList);
          onChange?.(newList);
          return;
        }
      } else {
        setSelectedList((prevList: string[]) => {
          const newList = [...prevList, val];
          onChange?.(newList);
          return newList;
        });
      }
    } else {
      if (val === "all") {
        setSelectedList([]);
        onChange?.([]);
      } else {
        setSelectedList((prevList: string[]) => {
          const newList = prevList.filter((item) => item !== val);
          onChange?.(newList);
          return newList;
        });
      }
    }

    // Update the options array with the correct checked status
    setOptions((prevOptions: OptionItem[]) =>
      prevOptions.map((item: OptionItem) =>
        item.value === val ? { ...item, checked } : item,
      ),
    );

    setGroupedOptions((prevGroupedOptions: GroupedItems) =>
      Object.keys(prevGroupedOptions).reduce((acc, itemKey) => {
        const items = prevGroupedOptions[itemKey];
        acc = {
          ...acc,
          [itemKey]: items.map((item: OptionItem) =>
            item.value === val ? { ...item, checked } : item,
          ),
        };
        return acc;
      }, {}),
    );
  };

  const actualItems = items || flattenedGroupedItems;
  const selectAllChecked = selectedList.length === actualItems?.length;

  return (
    <div ref={wrapperRef} className={s.inputGroup} data-testid={dataTestId}>
      {label && (
        <label
          className={cx(s.label, {
            [s.labelDisabled]: disabled,
          })}
          htmlFor={id}
        >
          {label}
          {required && <span className={s.labelRequired}>*</span>}
          {labelInfoIcon ?? null}
        </label>
      )}
      <div className="relative">
        <button
          onClick={toggleDropdown}
          type="button"
          className={cx(s.input, {
            "!px-3 !py-1": size === "small",
            [s.inputError]: hasError,
            [s.selectButtonDisabled]: disabled,
          })}
        >
          {preText
            ? selectedList.length > 0
              ? `${preText}: ${selectedList.join(", ")}`
              : `${preText}:`
            : placeholder}
          <ChevronIcon
            direction="down"
            className={cx({
              "-rotate-180 transform transition-all": isOpen,
              "rotate-0 transform transition-all": !isOpen,
            })}
          />
        </button>
      </div>
      <div className="relative z-10">
        <Transition as="div" show={isOpen} {...s.transitionProps}>
          <ul className={cx(s.selectMenu)}>
            {!hideAllOption && (
              <li className={cx(s.selectMenuItem, "w-full")}>
                <Checkbox
                  name="select-all"
                  value="select-all"
                  label="All"
                  checked={selectAllChecked}
                  onChange={(e) => handleOnChange("all", e.target.checked)}
                />
                {!hideCounts && actualItems?.length}
              </li>
            )}
            {options?.map((item) => (
              <li key={item.value} className={cx(s.selectMultiItem, "w-full")}>
                <Checkbox
                  name={item.label}
                  value={item.value}
                  label={item.label}
                  onChange={(e) => {
                    handleOnChange(item.value, e.target.checked);
                  }}
                  checked={item.checked}
                />
                {!hideCounts && (item.count || 0)}
              </li>
            ))}
            {Object.keys(groupedOptions)?.map((pillar) => {
              const topics = groupedOptions[pillar];
              const color = topics?.[0]?.groupColor || "";

              return (
                <div key={pillar}>
                  <div className="px-4 py-3" style={{ color }}>
                    <Text weight="bold">{pillar}</Text>
                  </div>
                  {topics?.map((item) => (
                    <li
                      key={item.value}
                      className={cx(s.selectMultiItem, "w-full")}
                    >
                      <Checkbox
                        name={item.label}
                        value={item.value}
                        label={item.label}
                        onChange={(e) => {
                          handleOnChange(item.value, e.target.checked);
                        }}
                        checked={item.checked}
                      />
                      {!hideCounts && (item.count || 0)}
                    </li>
                  ))}
                  <Divider margin="none" />
                </div>
              );
            })}
          </ul>
        </Transition>
      </div>
      {helperText && (
        <span
          id="helperText"
          className={cx(s.helperText, {
            [s.helperTextDisabled]: disabled,
          })}
        >
          {helperText}
        </span>
      )}
    </div>
  );
}
