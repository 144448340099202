import { isBefore } from "date-fns";
import { useRouter } from "next/router";
import { useEffect } from "react";
import UserRole from "@olivahealth/graphql-server/src/domain/value-objects/UserRole";
import { useUserData } from "../../../services/contexts/UserDataContext";

interface Props {
  children: JSX.Element;
}

const EMPLOYEE_ONBOARDING_RELEASE_DATE = new Date("2022-08-02");

/**
 * This middleware is responsible to check if the user completed the onboarding process.
 * If they signed up after we rolled out the feature, they are required to complete it
 * before they can do any other action on the platform.
 */
export default function EmployeeOnboardingMiddleware({
  children,
}: Props): JSX.Element {
  const { data, status } = useUserData();
  const { push, pathname } = useRouter();

  useEffect(() => {
    if (status !== "success" || !data) {
      return;
    }

    const isEmployee = data?.role?.some((role) =>
      [
        UserRole.EMPLOYEE,
        UserRole.MANAGER,
        UserRole.ORGANISATION_ADMIN,
      ].includes(role as any),
    );

    const isOnboardingCompleted = data.organisation?.hasTeamSessionsOnly
      ? data.name && data.surname
      : data.city && data.country;

    const userJoinedBeforeOnboardingRelease = isBefore(
      new Date(data?.createdAt ?? 0),
      EMPLOYEE_ONBOARDING_RELEASE_DATE,
    );

    if (
      isEmployee &&
      !isOnboardingCompleted &&
      !userJoinedBeforeOnboardingRelease &&
      pathname !== "/onboarding"
    ) {
      push("/onboarding");
    }
  }, [data, push, status]);

  return children;
}
