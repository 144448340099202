import { IconProps, SvgIcon } from "./SvgIcon";

export function ClipIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M19.2853 12.3459L12.0317 18.8612C9.87464 20.7987 6.55523 20.6207 4.61771 18.4636C2.68019 16.3065 2.85845 12.987 5.01555 11.0494L13.3851 3.53188C14.8231 2.2402 17.0356 2.35904 18.3273 3.79711C19.619 5.23517 19.5007 7.44785 18.0626 8.73954L9.69311 16.2571C8.97408 16.9029 7.86704 16.8436 7.22119 16.1245C6.57535 15.4055 6.63515 14.2993 7.35419 13.6535L14.6078 7.13829"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
