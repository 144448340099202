import { IconProps, SvgIcon } from "./SvgIcon";

type SkipTrackVariant = "left" | "right";

interface Props extends IconProps {
  variant?: SkipTrackVariant;
}

export function SkipTrackIcon({
  className,
  size,
  color = "currentColor",
  variant = "left",
}: Props): JSX.Element {
  const variants = {
    left: (
      <g>
        <path
          d="M15.4258 19.7258L7.35263 14.4817C5.54912 13.3102 5.54912 10.6898 7.35263 9.5183L15.4258 4.27416C16.5411 3.54973 18 4.35258 18 5.64909L18 18.3509C18 19.6474 16.5411 20.4503 15.4258 19.7258Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M5 19L5 5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    right: (
      <g>
        <path
          d="M7.57418 4.27416L15.6474 9.5183C17.4509 10.6898 17.4509 13.3102 15.6474 14.4817L7.57418 19.7258C6.45895 20.4503 5 19.6474 5 18.3509L5 5.64909C5 4.35258 6.45895 3.54973 7.57418 4.27416Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M18 5L18 19"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
  };
  return (
    <SvgIcon size={size} className={className}>
      {variants[variant]}
    </SvgIcon>
  );
}
