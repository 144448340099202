import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import type {
  GroupChannel,
  UnreadMessageCount,
} from "@sendbird/chat/groupChannel";
import {
  SendBirdProvider,
  sendbirdSelectors,
  useSendbirdStateContext,
} from "@sendbird/uikit-react";
import UserEventHandler from "@sendbird/uikit-react/handlers/UserEventHandler";
import { SendbirdChatType } from "@sendbird/uikit-react/types/lib/types";
import logger from "@olivahealth/logger/client";

import useTranslation from "../../hooks/useTranslation";
import { useUserData } from "./UserDataContext";

interface IChatContext {
  getGroupChannelsCount: () => Promise<number>;
  getGroupChannel: (channelUrl?: string) => Promise<GroupChannel | null>;
  initialized: boolean;
  unreadMessagesCount: number;
  userChatId: string;
}

export const ChatContext = createContext<IChatContext>({
  getGroupChannelsCount: () => Promise.resolve(0),
  getGroupChannel: () => Promise.resolve(null),
  initialized: false,
  unreadMessagesCount: 0,
  userChatId: "",
});

export const useChat = (): IChatContext => useContext(ChatContext);

function initContext(
  groupChannelSdk: SendbirdChatType,
  setUnreadMessagesCount: Dispatch<SetStateAction<number>>,
) {
  const userEventHandler = new UserEventHandler({
    onTotalUnreadMessageCountChanged: (
      unreadMessageCount: UnreadMessageCount,
    ) => {
      setUnreadMessagesCount(unreadMessageCount.groupChannelCount);
    },
  });

  groupChannelSdk.addUserEventHandler(
    "change-unread-messages-count",
    userEventHandler,
  );

  groupChannelSdk.groupChannel
    .getTotalUnreadMessageCount()
    .then(setUnreadMessagesCount)
    .catch((error) => {
      logger.warn(
        "ChatContext/getTotalUnreadMessageCount",
        "Error getting total unread messages count",
        {
          errorMessage: (error as Error).message,
          errorStack: (error as Error).stack,
        },
      );
    });
}

function ChatContextProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);
  const sendbirdState = useSendbirdStateContext();

  const groupChannelSdk = sendbirdSelectors.getSdk(sendbirdState);

  useEffect(() => {
    if (groupChannelSdk.currentUser) {
      initContext(groupChannelSdk, setUnreadMessagesCount);
    }
  }, [groupChannelSdk]);

  const getGroupChannel = useCallback(
    (channelUrl?: string) => {
      if (!channelUrl) {
        return groupChannelSdk.groupChannel
          .createMyGroupChannelListQuery({})
          .next()
          .then((groupChannels) => {
            logger.info(
              "ChatContext/getGroupChannel",
              "Successfully retrieved user group channel list",
              {
                groupChannelsCount: groupChannels.length,
                groupChanelsNames: groupChannels.map((channel) => ({
                  channelUrl: channel.url,
                  channelName: channel.name,
                })),
              },
            );

            return groupChannels[0];
          })
          .catch((error) => {
            logger.error(
              "ChatContext/getGroupChannel",
              "Error retrieving group channel",
              {
                errorMessage: (error as Error).message,
                errorStack: (error as Error).stack,
              },
            );
          });
      }

      return groupChannelSdk.groupChannel
        .getChannel(channelUrl)
        .then((groupChannel) => {
          logger.info(
            "ChatContext/getGroupChannel",
            "Successfully retrieved group channel",
            {
              channelUrl: groupChannel.url,
              channelName: groupChannel.name,
            },
          );
          return groupChannel;
        })
        .catch((error) => {
          logger.error(
            "ChatContext/getGroupChannel",
            "Error retrieving group channel",
            {
              errorMessage: (error as Error).message,
              errorStack: (error as Error).stack,
            },
          );
        });
    },
    [groupChannelSdk],
  );

  const getGroupChannelsCount = useCallback(
    () =>
      groupChannelSdk.groupChannel.getGroupChannelCount({}).catch((error) => {
        logger.error(
          "ChatContext/getGroupChannelsCount",
          "Error getting total group channel count",
          {
            errorMessage: (error as Error).message,
            errorStack: (error as Error).stack,
          },
        );
      }),
    [groupChannelSdk],
  );

  const contextValues = useMemo(
    () =>
      ({
        getGroupChannelsCount,
        getGroupChannel,
        initialized: Boolean(groupChannelSdk.currentUser),
        unreadMessagesCount,
        userChatId: sendbirdState.config.userId,
      }) as IChatContext,
    [
      getGroupChannelsCount,
      getGroupChannel,
      groupChannelSdk.currentUser,
      sendbirdState.config.userId,
      unreadMessagesCount,
    ],
  );

  return (
    <ChatContext.Provider value={contextValues}>
      {children}
    </ChatContext.Provider>
  );
}

export default function ChatProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { t } = useTranslation("messages");
  const { data: currentUser } = useUserData();

  const sendbirdAppId = process.env.NEXT_PUBLIC_SENDBIRD_APP_ID ?? "";
  const sendbirdUserId = currentUser?.sendbirdId;

  if (!sendbirdUserId) {
    return children;
  }

  return (
    <SendBirdProvider
      appId={sendbirdAppId}
      userId={sendbirdUserId}
      isMultipleFilesMessageEnabled
      uikitOptions={{
        groupChannel: {
          enableMarkdownForUserMessage: true,
        },
      }}
      colorSet={{
        "--sendbird-font-family-default": "'DM Sans', sans-serif, system-ui",
      }}
      stringSet={{
        MESSAGE_INPUT__PLACE_HOLDER: t("channelPanel.inputMessagePlaceholder"),
      }}
    >
      <ChatContextProvider>{children}</ChatContextProvider>
    </SendBirdProvider>
  );
}
