import { IconProps, SvgIcon } from "./SvgIcon";

export function MicrophoneIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M17 11.125C17 13.8864 14.7614 16.125 12 16.125C9.23858 16.125 7 13.8864 7 11.125L7 7.75C7 4.98858 9.23858 2.75 12 2.75C14.7614 2.75 17 4.98858 17 7.75V11.125Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M4.3573 14.3275C5.48829 17.4541 8.48323 19.6884 12 19.6884C15.5168 19.6884 18.5117 17.4541 19.6427 14.3275"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.1875 20.0312L12.1875 22.25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="11.9999"
        cy="11.1213"
        r="1.5"
        transform="rotate(135 11.9999 11.1213)"
        fill={color}
      />
    </SvgIcon>
  );
}
