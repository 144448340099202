import { Suspense, useState } from "react";
import { Transition } from "@headlessui/react";

import SurveyTitles from "@olivahealth/graphql-server/src/domain/value-objects/SurveyTitles";
import SurveyStatus from "@olivahealth/graphql-server/src/domain/value-objects/TriagingSubmissionStatus";

import { CenteredInfinityLoader } from "../../molecules/Loaders/InfinityLoader";
import NativeSurvey from "../../../components/common/NativeSurvey";
import { useUserData } from "../../../services/contexts/UserDataContext";

const getSurveyData = ({
  isFollowUpSurveyPending,
  followUpSurveySubmissionId,
  isGettingStartedSurveyPending,
  gettingStartedSurveySubmissionId,
}: {
  isFollowUpSurveyPending: boolean;
  followUpSurveySubmissionId?: string;
  isGettingStartedSurveyPending: boolean;
  gettingStartedSurveySubmissionId?: string;
}) => {
  if (isFollowUpSurveyPending) {
    return {
      surveyTitle: SurveyTitles.TRIAL_FOLLOW_UP,
      pendingSubmissionId: followUpSurveySubmissionId,
    };
  }

  if (isGettingStartedSurveyPending) {
    return {
      surveyTitle: SurveyTitles.TRIAL_GETTING_STARTED,
      pendingSubmissionId: gettingStartedSurveySubmissionId,
    };
  }

  return null;
};

export default function VIPTrialSurvey() {
  const [submitted, setSubmitted] = useState(false);
  const { data: user, refetchData } = useUserData();

  const surveySubmissions = user?.vipTrialSurveySubmissions;
  const followUpSurveySubmission = surveySubmissions?.followUpSurveySubmission;
  const gettingStartedSurveySubmission =
    surveySubmissions?.gettingStartedSurveySubmission;

  const isFollowUpSurveyPending =
    followUpSurveySubmission?.status === SurveyStatus.PENDING;
  const isFollowUpSurveyCompleted =
    followUpSurveySubmission?.status === SurveyStatus.COMPLETED;
  const isGettingStartedSurveyPending =
    gettingStartedSurveySubmission?.status === SurveyStatus.PENDING;

  const surveyData = getSurveyData({
    isFollowUpSurveyPending,
    followUpSurveySubmissionId: followUpSurveySubmission?.submissionId,
    isGettingStartedSurveyPending,
    gettingStartedSurveySubmissionId:
      gettingStartedSurveySubmission?.submissionId,
  });

  const pendingSubmissionId = surveyData?.pendingSubmissionId;
  const surveyTitle = surveyData?.surveyTitle;

  const shouldSkipGettingStartedSurvey =
    isFollowUpSurveyCompleted && isGettingStartedSurveyPending;

  const showSurvey =
    Boolean(pendingSubmissionId) &&
    !shouldSkipGettingStartedSurvey &&
    !submitted;

  return (
    <Transition
      as="div"
      leave="transition-all duration-1000 overflow-hidden"
      leaveFrom="transform scale-100 opacity-100 max-h-64"
      leaveTo="transform scale-95 opacity-0 max-h-0"
      show={showSurvey}
    >
      <div className="bg-white rounded-xl px-5 py-4 max-w-xl mt-6">
        <Suspense fallback={<CenteredInfinityLoader height="full" />}>
          <NativeSurvey
            i18nPrefix="nativeSurveys"
            onSubmitSuccess={() => {
              setSubmitted(true);
              refetchData();
            }}
            submissionId={pendingSubmissionId}
            title={surveyTitle}
            variant="embed"
          />
        </Suspense>
      </div>
    </Transition>
  );
}
