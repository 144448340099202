import { IconProps, SvgIcon } from "./SvgIcon";

export function ArrowSwitchIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M9.25348 3.25L6.54358 6.12927C5.81878 6.89937 5.81878 8.10063 6.54358 8.87073L9.25348 11.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19.0001 7.5L7.00006 7.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M14.7465 20.75L17.4564 17.8707C18.1812 17.1006 18.1812 15.8994 17.4564 15.1293L14.7465 12.25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M5 16.5L17 16.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
