import cx from "classnames";
import { ReactNode, HTMLProps } from "react";
import * as s from "./styles";

type CardPadding = "none" | "md" | "lg";
type CardRounding = "xl" | "2xl";

interface Props extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  dataTestId?: string;
  fullHeight?: boolean;
  border?: boolean;
  animateOnHover?: boolean;
  backgroundClassName?: string;
  boxShadowClassName?: string;
  padding?: CardPadding;
  rounding?: CardRounding;
}

export default function Card({
  children,
  dataTestId,
  fullHeight = false,
  border = true,
  animateOnHover = false,
  backgroundClassName,
  boxShadowClassName,
  padding,
  rounding = "xl",
  ...rest
}: Props): JSX.Element {
  let cardPadding = padding === "md" ? "p-4" : "p-0";
  if (padding === "lg") {
    cardPadding = "p-6";
  }

  const backgroundClassNameCustom = backgroundClassName || "bg-white";
  const boxShadowClassNameCustom = boxShadowClassName || "";

  return (
    <div
      className={cx(s.card, `rounded-${rounding}`, {
        "h-full": fullHeight,
        "hover:-translate-y-1 hover:drop-shadow cursor-pointer": animateOnHover,
        "border border-neutral-200": border,
        [backgroundClassNameCustom]: Boolean(backgroundClassNameCustom),
        [boxShadowClassNameCustom]: Boolean(boxShadowClassNameCustom),
        [cardPadding]: Boolean(cardPadding),
      })}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </div>
  );
}
