import { IconProps, SvgIcon } from "./SvgIcon";

export function HorizontalBarsIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 19L6 19C4.89543 19 4 18.1046 4 17C4 15.8954 4.89543 15 6 15L18 15C19.1046 15 20 15.8954 20 17C20 18.1046 19.1046 19 18 19ZM22 17C22 19.2091 20.2091 21 18 21L6 21C3.79086 21 2 19.2091 2 17C2 14.7909 3.79086 13 6 13L18 13C20.2091 13 22 14.7909 22 17ZM18 9L6 9C4.89543 9 4 8.10457 4 7C4 5.89543 4.89543 5 6 5L18 5C19.1046 5 20 5.89543 20 7C20 8.10457 19.1046 9 18 9ZM22 7C22 9.20914 20.2091 11 18 11L6 11C3.79086 11 2 9.20914 2 7C2 4.79086 3.79086 3 6 3L18 3C20.2091 3 22 4.79086 22 7Z"
        fill={color}
      />
    </SvgIcon>
  );
}
