import { IconProps, SvgIcon } from "./SvgIcon";

interface Props extends IconProps {
  variant: "up" | "down" | "left" | "right";
}

export function TriangleFilledIcon({
  className,
  color = "currentColor",
  variant = "up",
  size,
}: Props): JSX.Element {
  const variants = {
    left: (
      <path
        d="M6 13.7321C4.66667 12.9623 4.66667 11.0377 6 10.2679L15 5.0718C16.3333 4.302 18 5.26425 18 6.80385L18 17.1962C18 18.7358 16.3333 19.698 15 18.9282L6 13.7321Z"
        fill={color}
      />
    ),
    right: (
      <path
        d="M18 10.2679C19.3333 11.0377 19.3333 12.9623 18 13.7321L9 18.9282C7.66667 19.698 6 18.7358 6 17.1962L6 6.80385C6 5.26425 7.66667 4.302 9 5.0718L18 10.2679Z"
        fill={color}
      />
    ),
    up: (
      <path
        d="M10.2679 6C11.0377 4.66667 12.9623 4.66667 13.7321 6L18.9282 15C19.698 16.3333 18.7358 18 17.1962 18L6.80385 18C5.26425 18 4.302 16.3333 5.0718 15L10.2679 6Z"
        fill={color}
      />
    ),
    down: (
      <path
        d="M13.7321 18C12.9623 19.3333 11.0377 19.3333 10.2679 18L5.0718 9C4.302 7.66667 5.26425 6 6.80385 6L17.1962 6C18.7358 6 19.698 7.66667 18.9282 9L13.7321 18Z"
        fill={color}
      />
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {variants[variant]}
    </SvgIcon>
  );
}
