// eslint-disable-next-line no-restricted-imports
import { Trans } from "react-i18next";
import { ReactElement } from "react";
import { htmlSanitizer } from "@olivahealth/utils/stringManipulation";

interface TransProps {
  values?: Record<string, any>;
  i18nKey?: string;
  defaults?: string;
  components?: JSX.Element[] | Record<string, ReactElement>;
  count?: number;
}

// Ad hoc solution to remove html tags from translations
function CustomTrans({
  i18nKey,
  defaults,
  values,
  components,
  count,
}: TransProps) {
  return (
    <Trans
      i18nKey={i18nKey}
      defaults={defaults}
      values={values && htmlSanitizer(values)}
      components={components}
      count={count || values?.length || 1}
    />
  );
}

export default CustomTrans;
