import { IconProps, SvgIcon } from "./SvgIcon";

export function SunIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M11.9999 17.5C15.0375 17.5 17.4999 15.0375 17.4999 12C17.4999 8.9624 15.0375 6.49997 11.9999 6.49997C8.96237 6.49997 6.49994 8.9624 6.49994 12C6.49994 15.0375 8.96237 17.5 11.9999 17.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3.2V1M12 20.8V23"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.85562 5.85559L4.29999 4.29996M18.3007 18.3007L19.8563 19.8563"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.2 12H1M20.8 12H23"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3007 5.85559L19.8563 4.29996M5.85562 18.3007L4.29999 19.8563"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
