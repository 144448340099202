import { IconProps, SvgIcon } from "./SvgIcon";

export function WorksheetIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M3 10C3 6.13401 6.13401 3 10 3H14C17.866 3 21 6.13401 21 10V14C21 17.866 17.866 21 14 21H10C6.13401 21 3 17.866 3 14V10Z"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="8.10429"
        cy="9.19123"
        r="1.5"
        transform="rotate(-45 8.10429 9.19123)"
        fill={color}
      />
      <path
        d="M12 9.2812L16 9.2812"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="8.10429"
        cy="14.8088"
        r="1.5"
        transform="rotate(-45 8.10429 14.8088)"
        fill={color}
      />
      <path
        d="M12 14.6874L16 14.6874"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
