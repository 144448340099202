import { IconProps, SvgIcon } from "./SvgIcon";

interface Props extends IconProps {
  variant?: "closed" | "open";
}

export function EyeIcon({
  className,
  color = "currentColor",
  size,
  variant = "open",
}: Props): JSX.Element {
  const icon = {
    closed: (
      <g>
        <path
          d="M21.0075 14.5066C18.4691 16.323 15.3594 17.3921 12 17.3921C8.6406 17.3921 5.53089 16.323 2.9925 14.5066"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M2.99249 8.8855C5.53088 7.06905 8.64059 6 12 6C15.3594 6 18.4691 7.06904 21.0075 8.8855"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M5 3L20 20"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    open: (
      <g>
        <path
          d="M21.0075 14.5066C18.4691 16.323 15.3594 17.3921 12 17.3921C8.6406 17.3921 5.53089 16.323 2.9925 14.5066"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M2.99249 8.8855C5.53088 7.06905 8.64059 6 12 6C15.3594 6 18.4691 7.06904 21.0075 8.8855"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle
          cx="12"
          cy="11.5595"
          r="2"
          transform="rotate(-45 12 11.5595)"
          fill={color}
        />
      </g>
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {icon[variant]}
    </SvgIcon>
  );
}
