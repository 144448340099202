import { IconProps, SvgIcon } from "./SvgIcon";

export function QuoteIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M10.7404 4L6.96995 10.7765H7.09563C8.22678 10.7765 9.19035 11.1948 9.98634 12.0314C10.8242 12.8261 11.2432 13.8092 11.2432 14.9804C11.2432 16.4444 10.7404 17.6575 9.73497 18.6196C8.72951 19.5399 7.53552 20 6.15301 20C4.6867 20 3.45082 19.5399 2.44536 18.6196C1.48179 17.6575 1 16.4444 1 14.9804C1 14.1856 1.10474 13.4327 1.31421 12.7216C1.56557 11.9686 1.94262 11.1111 2.44536 10.149L5.71311 4H10.7404ZM23.4973 4L19.7268 10.7765H19.8525C20.9836 10.7765 21.9472 11.1948 22.7432 12.0314C23.5811 12.8261 24 13.8092 24 14.9804C24 16.4444 23.4973 17.6575 22.4918 18.6196C21.4863 19.5399 20.2924 20 18.9098 20C17.4435 20 16.2077 19.5399 15.2022 18.6196C14.2386 17.6575 13.7568 16.4444 13.7568 14.9804C13.7568 14.1856 13.8616 13.4327 14.071 12.7216C14.3224 11.9686 14.6995 11.1111 15.2022 10.149L18.4699 4H23.4973Z"
        fill={color}
      />
    </SvgIcon>
  );
}
