import { IconProps, SvgIcon } from "./SvgIcon";

export function GenderIcon({
  className,
  color = "currentColor",
  size,
}: Readonly<IconProps>): JSX.Element {
  return (
    <SvgIcon fill={color} size={size} className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="11" r="5" stroke={color} strokeWidth="2" />
        <path
          d="M19.1753 7L19.1753 5.00525C19.1753 3.90067 18.2799 3.00521 17.1753 3.00521L14.9998 3.00522"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M14.1806 8L17.7612 4.4194"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M10 17V22"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M8 20H12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
}
