import { useRef } from "react";
import { graphql, useFragment } from "react-relay";
import {
  Button,
  RadioQuestion,
  Rating,
  Text,
  RadioQuestionVariant,
} from "@olivahealth/oli-ui";
import { EMOJI_REGEX } from "@olivahealth/constants";

import useTranslation from "../../../hooks/useTranslation";
import { SingleChoiceRenderer_question$key } from "./__generated__/SingleChoiceRenderer_question.graphql";
import { SurveyAnswerInput } from "./__generated__/useNativeSurveyCreateMutation.graphql";

interface Props {
  initialAnswer: SurveyAnswerInput | null;
  onOptionChosen: (optionValue: string) => void;
  handleSubValueChange?: (optionValue: string, subValue: string) => void;
  question: SingleChoiceRenderer_question$key;
  variant?: RadioQuestionVariant;
}

const SingleChoiceRendererFragment = graphql`
  fragment SingleChoiceRenderer_question on Question {
    id
    options {
      label
      sublabel
      value
      subValue
    }
  }
`;

export default function SingleChoiceRenderer({
  initialAnswer,
  onOptionChosen,
  question,
  handleSubValueChange,
  variant,
}: Props): JSX.Element {
  const { t } = useTranslation("common", {
    keyPrefix: "nativeSurveys",
  });

  const questionData = useFragment<SingleChoiceRenderer_question$key>(
    SingleChoiceRendererFragment,
    question,
  );

  const initialValue = initialAnswer?.chosenOption.value;
  const selectedValue = useRef<string | undefined>(initialValue);

  const isRating =
    questionData.options.length === 11 &&
    questionData.options.every(
      (option, index) => option.value === index.toString(),
    );

  const onChange = (value: string) => {
    selectedValue.current = value;
    onOptionChosen(value);
  };

  const renderSubLabel = (): JSX.Element | null => {
    if (!selectedValue.current) {
      return null;
    }

    const currentOption = questionData.options.find(
      (option) => option.value === selectedValue.current,
    );
    const currentOptionSubLabel = currentOption?.sublabel;
    const shouldHideFooterSubLabel =
      (currentOption?.subValue?.length ?? -1) >= 0;

    return currentOptionSubLabel && !shouldHideFooterSubLabel ? (
      <Text color="valid-dark">{currentOptionSubLabel}</Text>
    ) : null;
  };

  if (isRating) {
    return (
      <Rating
        initialValue={initialValue ? parseInt(initialValue) : undefined}
        minLabel={questionData.options[0].sublabel ?? ""}
        maxLabel={questionData.options[10].sublabel ?? ""}
        onChange={(value) => onChange(value.toString())}
      />
    );
  }

  const renderableOptions = questionData.options.map((option) => {
    return {
      id: option.label,
      label: option.label,
      sublabel: option.sublabel,
      value: option.value,
      subValue: option.subValue,
    };
  });

  const isEmojiRenderer = renderableOptions.every((option) =>
    EMOJI_REGEX.test(option.label),
  );

  const isSimpleYesNo =
    renderableOptions.length === 2 &&
    renderableOptions.every(
      (option) =>
        (option.value === "YES" || option.value === "NO") && !option.sublabel,
    );

  if (isEmojiRenderer) {
    return (
      <div className="flex gap-x-10">
        {renderableOptions.map((option) => (
          <div
            className="flex flex-col items-center gap-y-3"
            key={option.value}
          >
            <Button
              variant="icon"
              onClick={() => onChange(option.value)}
              iconLeft={<Text size="5xl">{option.label}</Text>}
            />
            <Text color="secondary" size="xs" weight="bold">
              {option.sublabel}
            </Text>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <RadioQuestion
        handleSubValueChange={handleSubValueChange}
        onChange={onChange}
        selected={initialValue}
        selectedSubValue={initialAnswer?.chosenOption.subValue}
        question={{
          id: questionData.id,
          position: 0,
          type: "radio",
          options: renderableOptions,
        }}
        subValuePlaceholder={t("yourResponse")}
        variant={variant}
        renderInRow={isSimpleYesNo}
      />
      {renderSubLabel()}
    </div>
  );
}
