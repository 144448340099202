import ctl from "@netlify/classnames-template-literals";

export const headerContainer = ctl(`
  border-gray-200 
  border-b 
  z-10 
  flex 
  items-center 
  justify-between 
  bg-white 
  p-8 
  md:hidden
`);

export const sidebarContainer = ctl(`
  hidden 
  md:fixed 
  md:flex 
  md:h-full 
  md:w-80 
  md:flex-col 
  md:items-start 
  md:border-r-2 
  md:bg-white 
  md:p-3
`);

export const navigationItem = ctl(`
  flex 
  items-center 
  py-2 
  px-4 
  gap-6
`);

export const skeletonCircle = ctl(`
  w-6 
  h-6 
  bg-gray-300/40 
  rounded-full
`);

export const skeletonText = ctl(`
  h-5 
  bg-gray-300/40 
  rounded
`);

export const divider = ctl(`
  border-0 
  border-t 
  border-neutral-200 
  my-4
`);

export const sectionHeader = ctl(`
  ml-4 
  h-3 
  w-28
  bg-gray-300/40 
  rounded 
  mb-2
`);

export const adminToolsButton = ctl(`
  flex 
  items-center 
  justify-between 
  py-2 
  px-4 
  gap-6 
  bg-neutral-100 
  rounded-lg
`);

export const mobileHeaderButton = ctl(`
  animate-pulse 
  w-24 
  h-10 
  flex 
  gap-6 
  rounded-lg 
  bg-blue-50 
  py-2 
  px-4 
  text-blue-900
`);
