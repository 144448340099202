import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { signInWithCustomToken } from "@firebase/auth";

import { useAuth } from "@olivahealth/firebase/client";
import logger from "@olivahealth/logger/client";
import { TRACK_EVENT_JWT_LOGIN } from "@olivahealth/constants";
import { useAmplitude } from "../services/contexts/AmplitudeContext";

export type AuthAuthorizedType =
  | "loading"
  | "success"
  | "in_progress"
  | "error";

export default function useLoginWithCustomToken(
  setAuthStatus: (status: AuthAuthorizedType) => void,
) {
  const auth = useAuth();
  const router = useRouter();
  const { trackEvent } = useAmplitude();

  const removeTokenFromUrl = useCallback(async () => {
    const { query } = router;
    delete query.customToken;
    await router.replace({ pathname: router.pathname, query });
  }, [router]);

  useEffect(() => {
    const login = async () => {
      const { query } = router;
      const customToken = query.customToken as string;

      if (!customToken) {
        return;
      }

      setAuthStatus("loading");
      try {
        await signInWithCustomToken(auth, customToken);
        await removeTokenFromUrl();
        trackEvent("Logged in", { provider: TRACK_EVENT_JWT_LOGIN });
        setAuthStatus("success");
      } catch (error) {
        logger.error(
          "useLoginWithCustomToken",
          "Failed to login with custom token",
          {
            errorMessage: (error as Error).message,
            errorStack: (error as Error).stack,
          },
        );
        setAuthStatus("error");
        await removeTokenFromUrl();
      }
    };

    login();
  }, [auth, removeTokenFromUrl, router, setAuthStatus, trackEvent]);
}
