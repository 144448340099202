import { IconProps, SvgIcon } from "./SvgIcon";

export function CameraIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M9.5 4C13.0899 4 16 6.91015 16 10.5V13.5C16 17.0899 13.0898 20 9.5 20C5.91015 20 3 17.0899 3 13.5L3 10.5C3 6.91015 5.91015 4 9.5 4Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M19.4497 16.7869L17.3124 15.1052C15.5625 13.7283 15.5625 11.2717 17.3124 9.89482L19.4497 8.21312C19.7556 7.97245 20.1527 7.94254 20.4905 8.08715C20.8258 8.23068 21 8.4934 21 8.78366L21 16.2163C21 16.5066 20.8258 16.7693 20.4905 16.9129C20.1527 17.0575 19.7556 17.0275 19.4497 16.7869Z"
        stroke={color}
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
