import { useEffect, useRef, useState } from "react";
import useDebounce from "@olivahealth/utils/reactHooks/useDebounce";
import { CrossIcon, MagnifierIcon } from "@olivahealth/oli-ui";
import tokens from "../../../theme/tokens";
import * as s from "./styles";

interface Props {
  autoFocus?: boolean;
  debounceTimeout?: number;
  initialValue?: string;
  onSearchInputStartChanging?: (searchValue: string) => void;
  onSearchInput?: (searchValue: string) => void;
  placeholder: string;
  value?: string;
}

export default function SearchBar({
  autoFocus = false,
  debounceTimeout = 300,
  initialValue,
  onSearchInputStartChanging,
  onSearchInput,
  placeholder,
  value,
}: Props): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>(initialValue ?? "");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const debouncedSearchValue = useDebounce(searchValue, debounceTimeout);

  useEffect(() => {
    onSearchInput?.(debouncedSearchValue);
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (value === undefined) {
      return;
    }
    setSearchValue(value);
  }, [value]);

  useEffect(() => {
    onSearchInputStartChanging?.(searchValue);
  }, [searchValue]);

  return (
    <div className={s.searchBarWrapper}>
      <MagnifierIcon
        className="mr-2"
        color={tokens.colors["purple"][600]}
        size={24}
      />
      <input
        autoFocus={autoFocus}
        className={s.searchInputBar}
        placeholder={placeholder}
        ref={inputRef}
        role="textbox"
        value={searchValue}
        onChange={(event) => {
          setSearchValue(event.currentTarget.value);
        }}
      />

      {searchValue && (
        <button
          aria-label="Close"
          className={s.searchCloseButton}
          onClick={() => {
            setSearchValue("");
            inputRef.current?.focus();
          }}
        >
          <CrossIcon size={24} className="text-neutral-900" />
        </button>
      )}
    </div>
  );
}
