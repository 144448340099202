import { Dispatch, Fragment, ReactElement, ReactNode } from "react";
import {
  Listbox as HuiDropdown,
  ListboxOption,
  ListboxOptions,
  ListboxButton,
  Transition,
} from "@headlessui/react";
import cx from "classnames";
import { CheckIcon, ChevronIcon, Text } from "@olivahealth/oli-ui";
import * as s from "./styles";

export interface Option<T> {
  id: string | number;
  value: T;
  label: string | ReactElement;
  icon?: ReactNode;
}

export type DropdownSize = "sm" | "md";

export interface Props<T> {
  label?: string;
  onChange?: (value: T) => void;
  options: Array<Option<T>>;
  placeholder?: string;
  selected?: T;
  setSelected: Dispatch<T>;
  disabled?: boolean;
  prefix?: string;
  size?: DropdownSize;
  contentOverflow?: boolean;
  contentHeight?: string;
}

export default function Dropdown<T>({
  label,
  onChange,
  options,
  placeholder,
  selected,
  setSelected,
  disabled = false,
  prefix,
  size = "md",
  contentOverflow = false,
  contentHeight,
}: Props<T>): JSX.Element {
  const selectedOption = options.find((opt) => opt.value === selected);
  const showPlaceholder = placeholder && selected !== 0 && !selected;

  const onChangeCallback = (value: T) => {
    setSelected(value);
    onChange?.(value);
  };

  return (
    <HuiDropdown
      value={selected}
      onChange={onChangeCallback}
      disabled={disabled}
    >
      {({ open }) => (
        <>
          {label && (
            <HuiDropdown.Label>
              <Text variant="label" gutter="sm" color="secondary">
                {label}
              </Text>
            </HuiDropdown.Label>
          )}
          <div className="relative">
            <ListboxButton
              className={({ open }) =>
                cx(s.buttonWrapper(size), {
                  "!bg-neutral-100": open,
                })
              }
            >
              <span className="flex gap-x-2">
                {showPlaceholder && (
                  <Text lineClamp={1} weight="medium">
                    {placeholder}
                  </Text>
                )}
                {selectedOption?.icon}
                {selectedOption?.label && (
                  <span className="flex gap-x-1">
                    {prefix && (
                      <Text lineClamp={1} weight="medium">
                        {prefix}
                      </Text>
                    )}
                    <Text component="span" weight="medium">
                      {selectedOption.label}
                    </Text>
                  </span>
                )}
              </span>
              <ChevronIcon direction="down" />
            </ListboxButton>

            <Transition show={open} as="div" {...transitionProps}>
              <ListboxOptions
                as="div"
                className={cx(s.optionsWrapper, {
                  ["absolute"]: contentOverflow,
                  "overflow-y-scroll": contentHeight,
                })}
                static={false}
                style={{
                  height: contentHeight,
                }}
              >
                {options.map((option) => (
                  <ListboxOption
                    as="div"
                    className={({ active }) =>
                      cx(s.optionWrapper, {
                        "bg-neutral-100": option === selectedOption,
                        "bg-neutral-50": active && option !== selectedOption,
                      })
                    }
                    key={`${option.value}`}
                    value={option.value}
                  >
                    {({ selected }) => (
                      <>
                        {selected && (
                          <span>
                            <CheckIcon size={20} aria-hidden="true" />
                          </span>
                        )}
                        <div
                          className={cx("flex gap-x-2", {
                            "ml-8": !selected,
                          })}
                        >
                          {option.icon}
                          <Text variant="label">{option.label}</Text>
                        </div>
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </HuiDropdown>
  );
}

const transitionProps = {
  leave: "transition ease-in duration-100",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
};
