import { IconProps, SvgIcon } from "./SvgIcon";

export function InfoCircleIcon({
  className,
  color = "currentColor",
  fill,
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon fill={fill} size={size} className={className}>
      <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" />
      <path
        d="M12 15.7182L12 11.9114"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="12"
        cy="7.9375"
        r="1.5"
        transform="rotate(-45 12 7.9375)"
        fill={color}
      />
    </SvgIcon>
  );
}
