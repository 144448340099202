import { Transition } from "@headlessui/react";
import SubmissionStatus from "@olivahealth/graphql-server/src/domain/value-objects/TriagingSubmissionStatus";
import SurveyTitles from "@olivahealth/graphql-server/src/domain/value-objects/SurveyTitles";
import {
  AlertBanner,
  AlertBannerIconColors,
  CalendarIcon,
  Card,
} from "@olivahealth/oli-ui";
import NativeSurvey from "../../../../components/common/NativeSurvey";
import useTranslation from "../../../../hooks/useTranslation";
import useProfessionalNPSSurvey from "./useProfessionalNPSSurvey";

export default function ProfessionalNPSSurvey(): JSX.Element | null {
  const { t } = useTranslation("surveys", {
    keyPrefix: "professionalNPSSurvey",
  });
  const {
    data: {
      formInitialState,
      formStatus,
      pendingSubmissionId,
      surveyFlowToEventsMapping,
      variables,
    },
    effects: { onAnswersChanged, onDismissButtonClick, onSubmitSuccess },
  } = useProfessionalNPSSurvey();

  const renderSurveyFlowContent = () => {
    switch (formStatus) {
      case "OPENED":
        return (
          <Card border={false} padding="lg" rounding="xl">
            <NativeSurvey
              dismissButtonLabel={t("dismissButtonLabel")}
              formInitialState={formInitialState}
              onAnswersChanged={onAnswersChanged}
              onDismissButtonClick={onDismissButtonClick}
              onSubmitSuccess={onSubmitSuccess}
              submissionId={pendingSubmissionId}
              submissionStatus={SubmissionStatus.COMPLETED}
              surveyFlowToEventsMapping={surveyFlowToEventsMapping}
              title={SurveyTitles.PROFESSIONAL_NPS}
              variables={variables}
              variant="embed"
            />
          </Card>
        );
      case "DISMISSED":
        return (
          <AlertBanner
            icon={
              <CalendarIcon
                variant="check"
                color={AlertBannerIconColors["info"]}
              />
            }
            variant="info"
            heading={t("dismissedMessage")}
          />
        );
      case "COMPLETED":
        return <AlertBanner variant="success" heading={t("successMessage")} />;
      default:
        throw Error(`Invalid form status: ${formStatus}`);
    }
  };

  if (formStatus === "CLOSED") {
    return null;
  }

  return (
    <Transition
      as="div"
      leave="transition-all delay-[5000ms] duration-1000 overflow-hidden"
      leaveFrom="transform scale-100 opacity-100 max-h-96"
      leaveTo="transform scale-95 opacity-0 max-h-0"
      show={formStatus === "OPENED"}
    >
      <div className="max-w-[38rem]">{renderSurveyFlowContent()}</div>
    </Transition>
  );
}
