import { IconProps, SvgIcon } from "./SvgIcon";

export function CollapseIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M13.9063 16.25L16.6162 13.3707C17.341 12.6006 17.341 11.3994 16.6162 10.6293L13.9062 7.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M2.9375 12L15.9062 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.0625 6V18"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
