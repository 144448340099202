import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Question } from "../../types/Question";
import Text from "../../atoms/Text";
import * as s from "../../shared/surveyStyles";

interface Props {
  label?: string;
  onChange?: (value: string[]) => void;
  question: Question;
  selected?: string[];
}

export default function CheckboxQuestion({
  label,
  onChange,
  question,
  selected,
}: Props): JSX.Element | null {
  const [selectedItem, setSelectedItem] = useState(
    new Set(selected || undefined),
  );

  useEffect(() => {
    setSelectedItem(new Set(selected));
  }, [selected]);

  if (!question.options?.length) {
    return null;
  }

  const handleOnChange = (value: string) => {
    const newSelectedItem = new Set(selectedItem);

    if (newSelectedItem.has(value)) {
      newSelectedItem.delete(value);
    } else {
      newSelectedItem.add(value);
    }

    setSelectedItem(newSelectedItem);
    onChange?.(Array.from(newSelectedItem));
  };

  return (
    <>
      {question.question && (
        <Text variant="h3" component="h1" color="accent-primary" gutter="md">
          <span
            dangerouslySetInnerHTML={{
              __html: question.question,
            }}
          />
        </Text>
      )}
      {label && (
        <Text color="secondary" gutter="sm">
          {label}
        </Text>
      )}

      <div className={s.optionGroup}>
        {question.options.map((option) => {
          const isSelected = selectedItem.has(option.value);
          return (
            <button
              key={option.id}
              className={cx(s.option, isSelected && s.optionSelected)}
              onClick={(e) => {
                e.preventDefault();

                handleOnChange(option.value);
              }}
            >
              <div className={s.optionsContainer}>
                <div className="flex items-center">
                  {option.icon && <span className="mr-3">{option.icon}</span>}
                  <input
                    type="checkbox"
                    name={option.id}
                    defaultChecked={isSelected}
                    className="hidden"
                  />
                  <label className={s.optionLabel}>{option.label}</label>
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </>
  );
}
