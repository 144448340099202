import { IconProps, SvgIcon } from "./SvgIcon";

export function CourseIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <rect
        x="21.8571"
        y="3"
        width="17"
        height="19.7143"
        rx="7"
        transform="rotate(90 21.8571 3)"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="8.11278"
        cy="8.69122"
        r="1.5"
        transform="rotate(-45 8.11278 8.69122)"
        fill={color}
      />
      <path
        d="M12.0085 8.7812L16.0085 8.7812"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="8.11278"
        cy="14.3088"
        r="1.5"
        transform="rotate(-45 8.11278 14.3088)"
        fill={color}
      />
      <path
        d="M12.0085 14.1875L16.0085 14.1875"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
