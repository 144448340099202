import { IconProps, SvgIcon } from "./SvgIcon";

interface Props extends IconProps {
  variant?: "square" | "triangle" | "circle";
}

export function ExclamationIcon({
  className,
  color = "currentColor",
  size,
  variant = "triangle",
  fill,
}: Props): JSX.Element {
  const icon = {
    square: (
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7C11.4477 7 11 7.44772 11 8V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V8C13 7.44772 12.5523 7 12 7ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
          fill={color}
        />
        <rect
          x="4"
          y="3"
          width="16"
          height="18"
          rx="4"
          stroke={color}
          strokeWidth="2"
        />
      </g>
    ),
    triangle: (
      <g>
        <path
          d="M8.29789 5.30264C9.92131 2.23246 14.0787 2.23245 15.7021 5.30263L20.4482 14.2784C22.0774 17.3595 19.9119 21 16.7461 21H7.25389C4.08807 21 1.92261 17.3595 3.55178 14.2784L8.29789 5.30264Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M12.0001 7.81618L12.0001 11.623"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle
          cx="12"
          cy="15.5969"
          r="1.5"
          transform="rotate(135 12 15.5969)"
          fill={color}
        />
      </g>
    ),
    circle: (
      <g>
        <circle
          cx="12"
          cy="12"
          r="9"
          stroke={color}
          strokeWidth="2"
          fill={fill}
        />
        <path
          d="M12 7.81619L12 11.623"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle
          cx="12"
          cy="15.5969"
          r="1.5"
          transform="rotate(135 12 15.5969)"
          fill={color}
        />
      </g>
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {icon[variant]}
    </SvgIcon>
  );
}
