import { Logo } from "@olivahealth/oli-ui";
import { CenteredInfinityLoader } from "../../../molecules/Loaders/InfinityLoader";
import * as s from "./styles";

export default function InitialLoader() {
  return (
    <>
      {/* Mobile Header */}
      <header className={s.headerContainer}>
        <div className="flex items-center">
          <Logo height={28} color="light" />
        </div>
        <div className={s.mobileHeaderButton} />
      </header>

      {/* Sidebar for Desktop */}
      <div className={s.sidebarContainer}>
        <div className="mb-10 flex items-center p-2.5">
          <Logo height={28} color="light" />
        </div>

        <div className="w-full animate-pulse">
          <div className="flex flex-col gap-2">
            {/* Care Journey Section */}
            <div className={s.sectionHeader} />

            {/* Main Navigation Items */}
            {[1, 2, 3, 4].map((item) => (
              <div key={item} className={s.navigationItem}>
                <div className={s.skeletonCircle} />
                <div className={`${s.skeletonText} w-32`} />
              </div>
            ))}

            <hr className={s.divider} />

            {/* Messages & Profile Section */}
            {[1, 2].map((item) => (
              <div key={item} className={s.navigationItem}>
                <div className={s.skeletonCircle} />
                <div className={`${s.skeletonText} w-24`} />
              </div>
            ))}

            <hr className={s.divider} />

            {/* Crisis Support */}
            <div className={s.navigationItem}>
              <div className={s.skeletonCircle} />
              <div className={`${s.skeletonText} w-28`} />
            </div>
          </div>
        </div>
      </div>
      <main className="flex md:ml-80 h-full">
        <CenteredInfinityLoader height="full" />
      </main>
    </>
  );
}
