import { IconProps, SvgIcon } from "./SvgIcon";

export function BlockedIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" />
      <line
        x1="5.99324"
        y1="17.8429"
        x2="18.4932"
        y2="4.77468"
        stroke={color}
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
