import { IconProps, SvgIcon } from "./SvgIcon";

export function QuestionCircleIcon({
  className,
  color = "currentColor",
  size,
  strokeWidth = "2",
}: Readonly<IconProps>): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <circle cx="12" cy="12" r="9" stroke={color} strokeWidth={strokeWidth} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.27271 10.2121C9.27271 8.68201 10.5131 7.45455 12.0123 7.45455C13.4979 7.45455 14.7273 8.67097 14.7273 10.1874C14.7273 11.0909 14.5184 11.4101 14.135 11.8947L12.865 13.4997C12.5755 13.8656 12.0474 13.925 11.6855 13.6322C11.3237 13.3395 11.265 12.8055 11.5545 12.4396L12.8244 10.8346C12.9697 10.6509 13.0489 10.4227 13.0489 10.1874C13.0489 9.62245 12.5852 9.15151 12.0123 9.15151C11.4258 9.15151 10.951 9.63349 10.951 10.2121V10.4242C10.951 10.8928 10.5753 11.2727 10.1119 11.2727C9.64841 11.2727 9.27271 10.8928 9.27271 10.4242V10.2121Z"
        fill={color}
      />
      <path
        d="M11.0487 15.6075C11.0487 15.0895 11.4557 14.6695 11.9578 14.6695C12.4599 14.6695 12.8669 15.0895 12.8669 15.6075C12.8669 16.1255 12.4599 16.5455 11.9578 16.5455C11.4557 16.5455 11.0487 16.1255 11.0487 15.6075Z"
        fill={color}
      />
    </SvgIcon>
  );
}
