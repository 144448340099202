import { IconProps, SvgIcon } from "./SvgIcon";

type TriangleVariant = "up" | "down" | "left" | "right";

interface Props extends IconProps {
  variant?: TriangleVariant;
}

export function TriangleIcon({
  className,
  color = "currentColor",
  size,
  variant = "up",
}: Props): JSX.Element {
  const variants = {
    up: (
      <path
        d="M4.27416 14.4258L9.5183 6.35263C10.6898 4.54912 13.3102 4.54912 14.4817 6.35264L19.7258 14.4258C20.4503 15.5411 19.6474 17 18.3509 17L5.64909 17C4.35258 17 3.54973 15.5411 4.27416 14.4258Z"
        stroke={color}
        strokeWidth="2"
      />
    ),
    down: (
      <path
        d="M19.7258 9.57418L14.4817 17.6474C13.3102 19.4509 10.6898 19.4509 9.5183 17.6474L4.27415 9.57418C3.54972 8.45895 4.35258 7 5.64909 7L18.3509 7C19.6474 7 20.4503 8.45895 19.7258 9.57418Z"
        stroke={color}
        strokeWidth="2"
      />
    ),
    left: (
      <path
        d="M14.4258 19.7258L6.35263 14.4817C4.54912 13.3102 4.54912 10.6898 6.35264 9.5183L14.4258 4.27415C15.5411 3.54972 17 4.35258 17 5.64909L17 18.3509C17 19.6474 15.5411 20.4503 14.4258 19.7258Z"
        stroke={color}
        strokeWidth="2"
      />
    ),
    right: (
      <path
        d="M9.57418 4.27416L17.6474 9.5183C19.4509 10.6898 19.4509 13.3102 17.6474 14.4817L9.57418 19.7258C8.45895 20.4503 7 19.6474 7 18.3509L7 5.64909C7 4.35258 8.45895 3.54973 9.57418 4.27416Z"
        stroke={color}
        strokeWidth="2"
      />
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {variants[variant]}
    </SvgIcon>
  );
}
