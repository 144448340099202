import { IconProps, SvgIcon } from "./SvgIcon";

export function PhoneIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M6.92445 16.6864C3.20332 12.7436 2.59087 8.7843 3.21207 6.77279C3.52027 5.77483 4.20218 4.86888 4.87039 4.22557C5.21075 3.89791 5.57506 3.61155 5.92902 3.40024C6.24516 3.21151 6.68764 3 7.15172 3C7.56616 3 8.00318 3.17739 8.32107 3.33314C8.68574 3.5118 9.08187 3.75777 9.45946 4.04598C10.1678 4.58659 11.0145 5.43165 11.2803 6.43832C11.4277 6.99632 11.2886 7.55122 10.9955 7.96959C10.8533 8.17251 10.678 8.38466 10.5253 8.56865L10.4951 8.60501L10.4951 8.60504L10.4859 8.61619C10.3388 8.7934 10.2017 8.95862 10.076 9.12533C10.03 9.18635 9.98977 9.24205 9.9547 9.29284C9.7723 9.55695 9.72777 9.89257 9.83498 10.1951L9.83576 10.1973L9.83598 10.1979C9.92342 10.4442 10.0576 10.7357 10.2352 11.0509C10.5921 11.6845 11.0908 12.354 11.642 12.8852C12.2427 13.4641 12.7614 13.8811 13.2088 14.1491C13.2558 14.1772 13.301 14.2031 13.3444 14.2269C13.7355 14.4414 14.2213 14.3709 14.5354 14.0541C14.8854 13.701 15.3923 13.2094 16.125 12.7553C16.4461 12.5563 16.7822 12.5407 17.0136 12.5619C17.2499 12.5835 17.4729 12.6515 17.6612 12.7252C18.0401 12.8735 18.4385 13.1095 18.8057 13.3636C19.541 13.8727 20.3266 14.5782 20.8277 15.2026C21.105 15.5481 21.2452 15.9557 21.223 16.3867C21.2023 16.7876 21.0456 17.124 20.8892 17.3732C20.6226 17.798 20.198 18.198 19.9202 18.4554L19.919 18.4564L19.8503 18.5203L19.8476 18.5228C19.5049 18.8436 19.0909 19.205 18.65 19.5311C17.5373 20.3542 16.1862 20.551 14.9851 20.481C12.9693 20.3635 10.199 19.7163 6.97294 16.7344L6.92445 16.6864Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
