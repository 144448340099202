import { HTMLProps, ReactNode } from "react";
import ctl from "@netlify/classnames-template-literals";
import cx from "classnames";

type AlertVariant = "default" | "light" | "info" | "success" | "warn";

export interface Props {
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
  ref?: React.Ref<HTMLDivElement>;
  variant?: AlertVariant;
}

const baseStyles = ctl(`
  flex
  items-center
  py-1.5
  px-2
  rounded
  text-xs
`);

const variantStyles = {
  default: ctl(`
    ${baseStyles}
    border
    border-neutral-200
    text-neutral-900
  `),

  light: ctl(`
    ${baseStyles}
    !items-start
    bg-status-teal-50
    text-neutral-900
  `),

  info: ctl(`
    ${baseStyles}
    justify-center
    bg-blue-500
    bg-opacity-20
    text-blue-600
  `),

  success: ctl(`
    ${baseStyles}
    justify-center
    bg-green-300
    bg-opacity-20
    text-green-600
  `),

  warn: ctl(`
    ${baseStyles}
    bg-orange-100
    bg-opacity-50
    text-orange-600
  `),
};

export default function Alert({
  children,
  className,
  icon,
  variant = "default",
  ...props
}: Props & HTMLProps<HTMLDivElement>): JSX.Element {
  return (
    <div className={cx(variantStyles[variant], className)} {...props}>
      {icon && <span className="mr-2">{icon}</span>}
      {children}
    </div>
  );
}
