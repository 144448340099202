import {
  Badge,
  ClockIcon,
  ExclamationIcon,
  MessageBanner,
  Text,
} from "@olivahealth/oli-ui";
import { useRouter } from "next/router";
import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";

interface Props {
  surveyUrl: string;
}

export default function PendingSurveyBanner({ surveyUrl }: Props): JSX.Element {
  const { push } = useRouter();
  const { t } = useTranslation("employeeDashboard", {
    keyPrefix: "pendingSurveyBanner",
  });

  return (
    <MessageBanner
      isOpen
      icon={
        <Image
          alt="Pending Survey"
          src="/img/img_symptoms_tracking.png"
          height={96}
          width={96}
          className="-scale-y-100 -rotate-180"
        />
      }
      primaryAction={{
        action: () => {
          push(surveyUrl);
        },
        title: t("buttonLabel"),
      }}
      message={t("title")}
      description={
        <div>
          <Text gutter="md">{t("text1")}</Text>
          <Text gutter="md" size="sm" weight="bold" color="secondary">
            <span className="flex gap-2 items-center">
              <ClockIcon />
              {t("text2")}
            </span>
          </Text>
        </div>
      }
      showCloseButton={false}
      variant="info"
      badge={
        <Badge
          variant="yellowStatus"
          icon={<ExclamationIcon variant="triangle" />}
        >
          {t("badgeLabel")}
        </Badge>
      }
    />
  );
}
