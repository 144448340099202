import cx from "classnames";
import Link from "next/link";
import Image from "@olivahealth/web-client/src/components/common/Image";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "@olivahealth/constants";
import Text from "../../atoms/Text";

type DownloadBannerVariant = "horizontal" | "vertical";

interface PropsCommon {
  imageAlt?: string;
  title: string;
  qrCodeEl: JSX.Element;
  headerIcon?: JSX.Element;
  containerStyle?: string;
  hideAppleLink?: boolean;
  hideAndroidLink?: boolean;
}

type Props =
  | (PropsCommon & {
      variant?: Extract<DownloadBannerVariant, "horizontal">;
      imagePath: string;
    })
  | (PropsCommon & {
      variant?: Extract<DownloadBannerVariant, "vertical">;
      imagePath?: string;
    });

export default function AlertBanner({
  variant = "horizontal",
  imagePath,
  imageAlt,
  title,
  qrCodeEl,
  headerIcon,
  containerStyle,
  hideAppleLink = false,
  hideAndroidLink = false,
}: Readonly<Props>): JSX.Element | null {
  return variant === "horizontal" ? (
    <div className="border border-neutral-200 rounded-2xl px-8 flex gap-8 items-center mt-7">
      <div className="relative w-[100px] flex-shrink-0 self-end drop-shadow-md">
        <Image
          className="object-contain absolute bottom-0"
          src={imagePath as string}
          alt={imageAlt ?? ""}
          sizes="100px"
          width={100}
          height={155}
        />
      </div>

      <div className="w-full py-4 flex items-center justify-between flex-col lg:flex-row">
        <Text weight="bold">{title}</Text>
        <div className="flex items-center gap-4 lg:ml-16 flex-shrink-0">
          <span>{qrCodeEl}</span>
          {(!hideAndroidLink || !hideAppleLink) && (
            <div className="flex flex-col gap-1 justify-center items-center">
              <Link href={APP_STORE_LINK} target="_blank">
                <Image
                  className="object-contain"
                  src="/img/download-app-store.png"
                  alt="Download on App Store"
                  sizes="100px"
                  width={108}
                  height={32}
                />
              </Link>
              <Link href={PLAY_STORE_LINK} target="_blank">
                <Image
                  className="object-contain"
                  src="/img/download-google-play.png"
                  alt="Download on Play Store"
                  sizes="100px"
                  width={108}
                  height={32}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      className={cx(
        "relative p-4 flex flex-col gap-4 items-center bg-neutral-50 rounded-xl",
        containerStyle,
      )}
    >
      {headerIcon && (
        <div className="absolute -top-0 left-3 h-[72px] w-[72px] -translate-y-1/2">
          {headerIcon}
        </div>
      )}
      <div
        className={cx("flex gap-4 items-center", {
          "mt-6": headerIcon,
        })}
      >
        <Text fontFamily="sans" size="sm" weight="medium">
          {title}
        </Text>
        <span className="shrink-0 ml-2">{qrCodeEl}</span>
      </div>
    </div>
  );
}
