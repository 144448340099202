import { IconProps, SvgIcon } from "./SvgIcon";

export function UsersIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): JSX.Element {
  return (
    <SvgIcon size={size} className={className}>
      <circle cx="10.0156" cy="8.09115" r="3.5" fill={color} />
      <circle cx="17.25" cy="8.09115" r="2" fill={color} />
      <path
        d="M2.01562 19.4089C3.6125 16.4198 6.59717 14.4089 10.0156 14.4089C13.4341 14.4089 16.4188 16.4198 18.0156 19.4089"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.9844 15.8883C21.0394 14.1195 19.2731 12.9294 17.25 12.9294C16.6357 12.9294 16.045 13.0391 15.4938 13.2415"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
