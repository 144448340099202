import cx from "classnames";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import * as s from "./styles";

export interface CallToAction {
  action: () => void;
  label: string;
}

interface Props {
  callToAction?: CallToAction;
  icon?: JSX.Element;
  children?: JSX.Element;
  message?: string;
  size?: "md" | "sm";
}

export default function Notice({
  callToAction,
  icon,
  children,
  message,
  size = "md",
}: Props): JSX.Element {
  return (
    <div
      className={cx({
        [s.wrapper]: size === "md",
        [s.wrapperSm]: size === "sm",
      })}
    >
      <div className={s.content}>
        {icon && <div className="mr-2 pt-1 text-neutral-600">{icon}</div>}
        {message && (
          <Text color="secondary" size={size === "md" ? "base" : "sm"}>
            {message}
          </Text>
        )}
        {children}
        {typeof callToAction !== "undefined" && (
          <div key={callToAction.label}>
            <Button onClick={callToAction.action} variant="link">
              {callToAction.label}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
